import { Injectable } from '@angular/core'
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import {map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import {environment} from '../../environments/environment';
import * as globalConstant from '../shared/global'; 

@Injectable({
    providedIn: 'root'
})

export class HeaderService {

	
	apiDomain				        =	`${environment.domain}`;
	locationsApi                =   globalConstant.LOCATIONS_API;
	headerTextApi                =   globalConstant.HEADER_TEXT_API;
	
	
	
    constructor(
		public router: Router,
		private http: HttpClient
	){} 

    location(){
		
		return this.http.get<any>(this.apiDomain+this.locationsApi).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
          }));
	}



	headerText(){
		
		return this.http.get<any>(this.apiDomain+this.headerTextApi).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
          }));
	}
	
	

	
}
