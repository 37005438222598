import { Component } from '@angular/core';
import {
  Router,
  NavigationEnd,
  ActivatedRoute,
  NavigationStart,
} from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'grozapfront';

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.router.events.subscribe((event) => {
     
      if (event instanceof NavigationEnd) {
        // if(this.router.url == '/login')
        // {
        //   var element =  document.querySelector("body")
        //   if(element)  
        //   element.classList.add('login-signup');
          
        // }
        // else
        // {
        //   var element =  document.querySelector("body")
        //   if(element)  
        //   element.classList.remove('login-signup');
        // }
      }
    });

    
  }

  ngOnInit()
  {
    // if(!localStorage.getItem('location_id'))
    // {
    //   localStorage.setItem('location_id','10')
    // }
    
  }

  scrollTop(event) {
    window.scroll(0, 0); 
  }
}
 