import {Pipe, PipeTransform, NgZone, ChangeDetectorRef, OnDestroy} from "@angular/core";
@Pipe({
    name: 'dateAgo',
    pure: false
})
export class DateAgoPipe implements PipeTransform, OnDestroy {
	private timer: number;
	constructor(private changeDetectorRef: ChangeDetectorRef, private ngZone: NgZone) {}
	transform(value:string) {
		this.removeTimer();
		let d = new Date(value);
        let now = new Date();
		
		let utcTimeNow = new Date(now.getTime() + (now.getTimezoneOffset() * 60000));
		utcTimeNow.setHours(utcTimeNow.getHours()+2)
		let seconds = Math.round(Math.abs((utcTimeNow.getTime() - d.getTime())/1000));
		let timeToUpdate = (Number.isNaN(seconds)) ? 1000 : this.getSecondsUntilUpdate(seconds) *1000;

		// let utcTimeNow1 = new Date(now.getTime() + (now.getTimezoneOffset() * 60000));
		// var offset = -300; //Timezone offset for EST in minutes.
		// var utcTimeNow = new Date(utcTimeNow1.getTime() + offset*60*1000);
		// let seconds = Math.floor(Math.abs((utcTimeNow.getTime() - d.getTime())/1000));
		// let timeToUpdate = (Number.isNaN(seconds)) ? 1000 : this.getSecondsUntilUpdate(seconds) *1000;
		
		// this.removeTimer();
		// let d = new Date(value);
		// let now = new Date();
		// let seconds = Math.floor(Math.abs((now.getTime() - d.getTime())/1000));
		// let timeToUpdate = (Number.isNaN(seconds)) ? 1000 : this.getSecondsUntilUpdate(seconds) *1000;
			this.timer = this.ngZone.runOutsideAngular(() => {
			if (typeof window !== 'undefined' && typeof window !== null ) {
				return window.setTimeout(() => {
					this.ngZone.run(() => this.changeDetectorRef.markForCheck());
				}, timeToUpdate);
			}
			return null;
		});
		let minutes = Math.floor(Math.abs(seconds / 60));
		let hours = Math.floor(Math.abs(minutes / 60));
		let days = Math.floor(Math.abs(hours / 24));
		let months = Math.floor(Math.abs(days/30.416));
		let years = Math.floor(Math.abs(days/365));
		if (Number.isNaN(seconds)){
			return '';
		} else if (seconds <= 45) {
			return 'Just now';
		} else if (seconds <= 90) {
			return 'a minute ago';
		} else if (minutes <= 45) {
			return minutes + ' minutes ago';
		} else if (minutes <= 90) {
			return 'an hour ago';
		} else if (hours <= 22) {
			return hours + ' hours ago';
		} else if (hours <= 36) {
			return 'yesterday';
		} else if (days <= 25) {
			return days + ' days ago';
		} else if (days <= 45) {
			return 'a month ago';
		} else if (days <= 345) {
			return months + ' months ago';
		} else if (days <= 545) {
			return 'a year ago';
		} else { // (days > 545)
			return years + ' years ago';
		}
	}
	ngOnDestroy(): void {
		this.removeTimer();
	}
	private removeTimer() {
		if (this.timer) {
			window.clearTimeout(this.timer);
			this.timer = null;
		}
	}
	private getSecondsUntilUpdate(seconds:number) {
		let min = 60;
		let hr = min * 60;
		let day = hr * 24;
		if (seconds < min) { // less than 1 min, update every 2 secs
			return 2;
		} else if (seconds < hr) { // less than an hour, update every 30 secs
			return 30;
		} else if (seconds < day) { // less then a day, update every 5 mins
			return 300;
		} else { // update every hour
			return 3600;
		}
	}
}

