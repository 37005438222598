import { Injectable } from '@angular/core'
import { Subject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import {map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import {environment} from '../../environments/environment';
import * as globalConstant from '../shared/global'; 

@Injectable({
    providedIn: 'root'
})

export class WishlistService {

	
    private wishlistCount = new Subject<any>();
	apiDomain				        =	`${environment.domain}`;
    addToWishlistApi            =   globalConstant.ADD_TO_WISHLIST_API;
    removeFromWishlistApi       =   globalConstant.REMOVE_FROM_WISHLIST_API;
    getWishlistProductsApi      =   globalConstant.WISHLIST_PRODUCTS_API;
    getRecentViewProductsApi    =   globalConstant.RECENT_VIEW_API ;
	
	
	
    constructor(
		public router: Router,
		private http: HttpClient
    ){} 
       

    sendWishlistCount(count:number) {
        this.wishlistCount.next( count );
    }

    getWishlistCount(): Observable<any> {
        return this.wishlistCount.asObservable();
    }

    addToWishList(product_id){

        const httpOptions = {
			headers: new HttpHeaders({
		
				"Authorization": `Bearer ${JSON.parse(localStorage.getItem('authUser')).token}`
			})
		};
		
		return this.http.get<any>(this.apiDomain+this.addToWishlistApi+product_id, httpOptions).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
          }));
    }
    
    removeFromWishlist(product_id){
        const httpOptions = {
			headers: new HttpHeaders({
		
				"Authorization": `Bearer ${JSON.parse(localStorage.getItem('authUser')).token}`
			})
		};
		
		return this.http.get<any>(this.apiDomain+this.removeFromWishlistApi+product_id, httpOptions).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
          }));
    }

    wishlistProducts(page){
        const httpOptions = {
			headers: new HttpHeaders({
		
				"Authorization": `Bearer ${JSON.parse(localStorage.getItem('authUser')).token}`
			})
		};
		
		return this.http.get<any>(this.apiDomain+this.getWishlistProductsApi+'?page='+page , httpOptions).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
          }));
    }

    recentViewProducts(page){
        const httpOptions = {
			headers: new HttpHeaders({
		
				"Authorization": `Bearer ${JSON.parse(localStorage.getItem('authUser')).token}`
			})
		};
		
		return this.http.get<any>(this.apiDomain+this.getRecentViewProductsApi+page, httpOptions).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
          }));
    }

	

	
}
