import { Component, ElementRef, OnInit, TemplateRef, ViewChild, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DashboardService } from 'src/app/services/dashboard.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { MessageService } from 'src/app/message.service';
import * as globalConstant from '../../../shared/global';
@Component({
  selector: 'app-manage-addresses',
  templateUrl: './manage-addresses.component.html',
  styleUrls: ['./manage-addresses.component.css']
})
export class ManageAddressesComponent implements OnInit {

  locationColor: boolean = false;
  longitude: any;
  latitude: any;
  address: string = '';
  zoom: number = 3;
  private geoCoder;
  @ViewChild('searchInput') public searchElementRef: ElementRef;
  @ViewChild('closeAddressModal') public closeAddressModal : ElementRef;
  selectAddressForm : FormGroup;

  manageAddressesForm  : FormGroup;
  currentUrl : any ;
  address_id : number = 0;
  editAddress_id : number = 0;
  address_index : number = 0;
  submitted : boolean = false;
  addresses : any = [];
  modalRef : BsModalRef;
  type : string = 'add';
  officeAddress : any = [];
  homeAddress : any = [];
  whichOne : string = '';
  error : boolean = true;
  is_phone_invalid: any = false;
  constructor(
    private formBuilder: FormBuilder,
    private DashboardService : DashboardService,
    private Toastr : ToastrManager,
    private modalService: BsModalService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private router : Router,
    private messageService : MessageService,
  ) {
    this.latitude        =  JSON.parse(localStorage.getItem('latitude'));
    this.longitude       =  JSON.parse(localStorage.getItem('longitude'));

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
      } else if (event instanceof NavigationEnd) {
        this.currentUrl =  this.router.url.split('?')[0];
      }
    });    
   }

  ngOnInit(): void {
    
    this.autoLocation();
    this.initializeForm();
    // location
    // this.mapsAPILoader.load().then(() => {
    //   this.geoCoder = new google.maps.Geocoder;
    //   this.setCurrentLocation();
    // });
    this.getAddresses();
  }

  getSearchElement(element){
    this.searchElementRef = element;
  }
  

  initializeForm(){
    // if(this.currentUrl == '/manage-addresses'){
      this.manageAddressesForm = this.formBuilder.group({ 
      
        name: ['',[Validators.required]],      
        number: ['', [Validators.required, Validators.minLength(9), Validators.maxLength(9)]],
        address_type: ['home',[Validators.required]],
      });
    // }

    if(this.currentUrl == '/checkout'){
      this.selectAddressForm = this.formBuilder.group({ 
      
        address_radio: ['0',[Validators.required]],      
        // number: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
        // address_type: ['',[Validators.required]],
      });
    }
  }

  get f() { return this.manageAddressesForm.controls; }

  selectedAddress(address){
    this.messageService.sendCartDetails(address);
  }

  onSubmit(){
    
    this.submitted = true;
    this.is_phone_invalid = false;
    const regex = new RegExp(globalConstant.PHONE_NUMBER_VALIDATOR);
    if (regex.test(this.messageService.onInputChange(this.manageAddressesForm.get('number').value))) {
      this.is_phone_invalid = false;
    }
    else {
      this.is_phone_invalid = true
    }
    if (this.manageAddressesForm.invalid || this.is_phone_invalid) {
      return;
    }


    if(this.type == 'Add'){
      let data = {
        name:           this.manageAddressesForm.get('name').value,      
        phone_number:         this.messageService.onInputChange(this.manageAddressesForm.get('number').value),
        type:   this.manageAddressesForm.get('address_type').value,
        address:    this.address,
        latitude        :  this.latitude,
        longitude       :  this.longitude,
      }

      this.DashboardService.addAddress(data).subscribe(res=>{
        if(res.status == 'success'){
          this.getAddresses();
          // this.modalRef.hide();
          this.closeAddressModal.nativeElement.click();
          this.manageAddressesForm.reset();
          this.address = '';
          this.submitted = false;
          this.Toastr.successToastr(res.msg,'Success');
        }else{
          this.submitted = false;
          this.Toastr.errorToastr('Error!',res.msg);
        }
      })

    }
    else if(this.type == 'Edit'){
      let editData = {
        name:           this.manageAddressesForm.get('name').value,      
        phone_number:        this.messageService.onInputChange(this.manageAddressesForm.get('number').value),
        type:   this.manageAddressesForm.get('address_type').value,
        address:    this.address,
        latitude        :  this.latitude,
        longitude       :  this.longitude,
        id              :  this.address_id
      }

      if(this.address_id){
        this.DashboardService.editAddress(editData).subscribe(res=>{
          if(res.status == 'success'){
            this.getAddresses();
            this.submitted = false;
            this.address = '';
            this.manageAddressesForm.reset();
            this.closeAddressModal.nativeElement.click();
            // this.modalRef.hide();
            this.Toastr.successToastr(res.msg,'Success');
          }else{
            this.submitted = false;
            this.Toastr.errorToastr('Error!',res.msg);
          }
        })
      }
    }

  }
  

  numberOnly(event): boolean {
    
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode!==46) {
      return false;
    }
    return true;

  }

  edit(){
    let data:any = [];
    if(this.whichOne == 'home'){
      data = this.homeAddress[this.editAddress_id];  
    }else{
      data = this.officeAddress[this.editAddress_id]; 
    }
    this.address = data.address;
      this.latitude = data.latitude;
      this.longitude = data.longitude;
      
      this.mapsAPILoader.load().then(() => {
        this.geoCoder = new google.maps.Geocoder;
        this.latitude = parseFloat(this.latitude);
        this.longitude = parseFloat(this.longitude);
        this.getAddress(this.latitude, this.longitude);
      });

    this.manageAddressesForm.patchValue({
      name:           data.name,      
      number:         ((data.phone_number && data.phone_number.split('+27')[1]) ? data.phone_number.split('+27')[1] : ''),
      address_type:   data.type,
    });
  }

  close(){
    this.submitted = false;
    this.manageAddressesForm.reset();
    this.address = '';
    this.manageAddressesForm.get('address_type').patchValue('');
    // this.modalRef.hide();
  }

  getAddresses(){
    this.homeAddress = [];
    this.officeAddress = [];
    this.DashboardService.addresses().subscribe(res=>{
      if(res.status == 'success'){
        this.addresses = res.data;
        if(this.currentUrl == '/checkout'){
          this.selectedAddress(this.addresses[0]);
        }
        for(let address of this.addresses){
          if(address.type == 'home'){
            this.homeAddress.push(address);
          }else{
            this.officeAddress.push(address);
          }
        }
      }else{
        this.addresses = [];
      }
    })
  }

  removeAddress(){
    
    this.DashboardService.deleteAddress(this.address_id).subscribe(res=>{
      if(res.status == 'success'){
        if(this.whichOne == 'home'){
          this.homeAddress.splice(this.address_index,1)
        } 
        if(this.whichOne == 'office'){
          this.officeAddress.splice(this.address_index,1)
        }
        this.modalRef.hide();        
        this.Toastr.successToastr(res.msg,'Success');
      }else{
        this.Toastr.errorToastr('Error!',res.msg);
      }
    })
  }
  // template: TemplateRef<any>,
  openModal(type:string, index?:number, id?:number, whichOne?:string ) {
    this.whichOne = whichOne  
    this.address = '';  
    this.latitude = '';
    this.longitude = '';
    if(type && type == 'add'){
       
    
      this.type = 'Add';
      this.address = '';
      this.searchElementRef.nativeElement.value = this.address
      this.mapsAPILoader.load().then(() => {
        this.geoCoder = new google.maps.Geocoder;
        this.setCurrentLocation();
      });
      // this.modalRef = this.modalService.show(template, { class: 'modal-dialog-centered modal-lg' });
    }

    if(type && type == 'edit'){
      this.address_id = id;
      this.editAddress_id = index;
      this.type = 'Edit';
      this.address = '';
      this.edit();
      // this.modalRef = this.modalService.show(template, { class: 'modal-dialog-centered modal-lg' });
    }
  }

  openDeleteModal(template: TemplateRef<any>,id:number , index:number, whichOne?:string){
    this.whichOne = whichOne;
    this.address_id = id;
    this.address_index = index;
    this.modalRef = this.modalService.show(template, { class: 'modal-dialog-centered confirmPopup' } );
  }

  hideModal(){
    this.modalRef.hide();
  }

  // for location
  markerDragEnd($event: MouseEvent) {    
    this.locationColor = false;
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    // this.alertService.loading.next(true);
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {

      // this.alertService.loading.next(false);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          var str = results[0].formatted_address;
          this.address = str.replace("Unnamed Road,", "");
          this.searchElementRef.nativeElement.value =this.address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

   // Get Current Location Coordinates
   setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.locationColor =  !this.locationColor;
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  autoLocation(){
 
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      // this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
       autocomplete.setComponentRestrictions({
        country: ["ZA"],
      });
      
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
           this.address = place.formatted_address;            
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });
  }

  keypress(){
    this.error = false;
  }

  typeOfAddress(type){
    this.manageAddressesForm.get('address_type').patchValue(type);
  }
  
  

}
