import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-front-layout',
  templateUrl: './front-layout.component.html',
  styleUrls: ['./front-layout.component.css']
})
export class FrontLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  scrollTop(event) {
    window.scroll(0, 0); 
  }

}
