import { NgModule } from '@angular/core';
import { CommonModule,DatePipe } from '@angular/common';
import { ToastrModule } from 'ng6-toastr-notifications';
import { FormsModule,ReactiveFormsModule} from '@angular/forms';
import { AgmCoreModule} from '@agm/core'  
import { AgmDirectionModule } from 'agm-direction'  
import { environment } from 'src/environments/environment' 
import { ManageAddressesComponent } from '../components/dashboard/manage-addresses/manage-addresses.component';
import { RouterModule } from '@angular/router';
import { IConfig, NgxMaskModule } from "ngx-mask";
import { DateAgoPipe } from '../pipes/date-ago.pipe';

const maskConfig: Partial<IConfig> = {
  validation: false,
};
@NgModule({
  declarations: [
    ManageAddressesComponent,
    DateAgoPipe
  ],
  imports: [
    CommonModule,
    ToastrModule.forRoot(),
    FormsModule, 
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: environment.mapApiKey,
      libraries: ['places']
    }),
    AgmDirectionModule,
    RouterModule,
    NgxMaskModule.forRoot(maskConfig),
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AgmDirectionModule,
    AgmCoreModule,
    ManageAddressesComponent,
    NgxMaskModule,
    DateAgoPipe
  ],
  providers : [
    DatePipe
  ]
})
export class ShareModule { }
