import { Component, OnInit, HostListener,ElementRef,ViewChild, PLATFORM_ID, Inject , Injector, OnDestroy } from '@angular/core';
import { ChatService } from '../../services/chat.service';
import {Router, ActivatedRoute ,NavigationEnd} from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ToastrManager } from 'ng6-toastr-notifications';
import { WebChatService } from '../../chat-service.service';
import {ReplaySubject} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { Title } from '@angular/platform-browser';
import * as globalConstant from '../../shared/global';
import { MessageService } from 'src/app/message.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit, OnDestroy {

  @ViewChild('message') message: ElementRef; 
  order_number : any = ''
  chats : any = []
  keyword : any = ''
  searchUser: Subject<string> = new Subject<string>();
  active_id : any = 0

  messages : any = ''
  messages1 : any = ''
  logged_user_id : any = ''
  current_user_chat  : any;
  submitted : boolean = false;
  sendFileData: any = [];
  messageError:boolean= false;
  timeout: any = null;
  is_user_type : boolean = false; 
  messageThreadData : any = [] 
  active_sender_id : any = 0 
  returant_order_number : any = 0
  resturant_name :any = ''
  reciver_id : any =''
  from_page : any = '';
  messageType:any;
  senderType:any;

    // Variable to store shortLink from api response
    shortLink:any;
  
    file: File = null; // Variable to store file

    currentImage:any='';

  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(private chatService :ChatService,private router :Router,private activatedRoute: ActivatedRoute
    ,public toastr: ToastrManager,
     private chat: WebChatService,
    private titleService: Title,
    @Inject(PLATFORM_ID) private platformId,
    private injector: Injector,  
    private messageService : MessageService) {

    this.activatedRoute.queryParams.subscribe(params => {
      try { 
        decodeURIComponent(params['order'])
        // decodeURIComponent(params['subject'])
      } catch(e) { 
        this.router.navigate(['/'])
        return;
      }
      // order id
      this.order_number = (decodeURIComponent(params['order']).split('&')[1] !== undefined && decodeURIComponent(params['order']).split('&')[1] !== null && decodeURIComponent(params['order']).split('&')[1] !== '' ) ? decodeURIComponent(params['order']).split('&')[1] : ''    
      this.reciver_id = (decodeURIComponent(params['id']).split('?')[1] !== undefined && decodeURIComponent(params['id']).split('?')[1] !== null && decodeURIComponent(params['id']).split('?')[1] !== '' ) ? decodeURIComponent(params['id']).split('?')[1] : ''
     
      if((this.order_number == '' || this.order_number == undefined || this.order_number == null) || (this.reciver_id == '' || this.reciver_id == undefined || this.reciver_id == null))
      {
        this.router.navigate(['/'])
      }

      if(localStorage.getItem('order_detail') && isPlatformBrowser(this.platformId))
      {
        this.returant_order_number =  JSON.parse(localStorage.getItem('order_detail')).id
        this.resturant_name = JSON.parse(localStorage.getItem('order_detail')).name
        this.from_page = JSON.parse(localStorage.getItem('order_detail')).from;
        this.messageType = JSON.parse(localStorage.getItem('order_detail')).type;
        this.senderType = 'customer';
      }
      
    }); 

    if(isPlatformBrowser(this.platformId)){ 
      // this.setTitle()
      this.messageService.getUpdateTitleMessage().subscribe(res=>{
        if(res == true)
        {
          // this.setTitle()
        }
      })
      this.logged_user_id = JSON.parse(localStorage.getItem('authUser'))?.data?.id
    }

    this.searchUser.pipe(
      debounceTime(500))
      .subscribe(searchText => {
        this.keyword = searchText       
      });

   
   }

 


  ngOnInit(): void {
    if(isPlatformBrowser(this.platformId)){
        this.chat.initSocket(true,{'loginChatRoom': `${this.messageType}_${this.logged_user_id}_${this.reciver_id}_${this.order_number}`});

        this.getChatHistory(this.reciver_id,this.order_number);

        this.chat.getMessages().subscribe((msg: any) => {
          if(msg){      
            this.unShiftChat(msg.receiver_id,msg.sender_name,msg.message,msg.datetime,msg.sender_id,msg.message_id,msg.order_id);
          }                
        });

        this.chat.getUserOnline().subscribe((message: any) => {
          if(message){
            for(let i=0;i < this.chats.length ; i++)
            {
              if(this.chats[i].id == message.user_id)
              {
                this.chats[i].is_online = (message.is_online ==1) ? 'online' : 'offline';                   
              }
            }
          }              
        });
    }

    if(isPlatformBrowser(this.platformId)){
      var body = document.body;
      body?.classList?.add("pt-0");       
    }  
  }

  ngAfterViewInit() {
    this.getDocHeight();

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.getDocHeight(); 
  }

  getDocHeight() {
    if(isPlatformBrowser(this.platformId)){
      setTimeout(() => {
 
        var BodyH = document.documentElement?.clientHeight || 0;
        var HeadH = document.getElementById('pageHeader')?.clientHeight 
        var calculation = BodyH - HeadH;

        document.getElementById('dashChatInner').style.height = calculation + 'px';


        var parentHeight = document.getElementById('dashChatInner')?.clientHeight || 0;
        var parentHeadHeight = document.getElementById('chatHeader')?.clientHeight || 0;
        var parentFootHeight = document.getElementById('chatFooter')?.clientHeight || 0;
        var Catcalculation = parentHeight - (parentHeadHeight+parentFootHeight);
        if (document.getElementById('chatInner')) {
          document.getElementById('chatInner').style.height = Catcalculation + 'px';
          let scroll = document.getElementById('chatInner').scrollHeight;
          document.getElementById('chatInner').scrollTo(0, scroll);
        }
      }, 5);
    }
  }

  changed(text: string) {
    this.searchUser.next(text);
  }

  unShiftChat(receaver_id,name,message,time,sender_id,message_id,socket_order_id)
  { 

   
      let now = new Date();      
      let chatData = {
        "id":receaver_id,
        "sender_id":sender_id,
        "receiver_id":receaver_id,
        "message":message,
        "is_media":0,
        "is_read":1,
        "created_at":now
      };
      
        this.messageThreadData.push(chatData);
      // }
      this.scrollToBottom()
    // }
   
  } 

  activeChat(chat_id,active_order_id)
  {
    this.active_id = active_order_id
    for(let i = 0 ;i<this.chats.length;i++)
    {
        this.chats[i].isActive = 0;
    }
    for(let i = 0 ;i<this.chats.length;i++)
    {
      if(this.chats[i].order_id == active_order_id)
      {
        this.chats[i].isActive = 1;
        this.chats[i].unread_msg_count = 0
        break;
      }
      
    }
 
    
    let index = this.chats.findIndex(({ order_id }) => order_id ==active_order_id);
    if(index !== -1)
    {
      this.current_user_chat =  this.chats[index];
      
      this.getChatHistory(chat_id,active_order_id)
    }
  }

 async getChatHistory(sender_id,order_id)
  {

    let res;

    if(this.messageType == 'customer_shopper'){
      res = await  this.chatService.chatHistory(this.logged_user_id,sender_id,order_id).toPromise();
    }else{
      res = await this.chatService.chatDriverHistory(this.logged_user_id,sender_id,order_id).toPromise(); 
      
    }
   
    

    // this.chatService.chatHistory(this.logged_user_id,sender_id,order_id).subscribe(res=>{
      if(res.status == 'success')
      {
        this.getDocHeight()
        if(res.data.length > 0)
        {

          let now = new Date();
          for(let i=0;i<res.data.length;i++)
          {
            let d = new Date(res.data[i].created_at);          
            
            res.data[i].created_at = d;          
            
          }
          
          this.messageThreadData = res.data;
          this.scrollToBottom()
        }
        else
        { 
          this.messageThreadData = []
        }

     
      
       
      }
    // });



  }

  messageSend(message) {
    this.submitted = true;
    // validations check empty message field
    if(!this.shortLink &&  (message.trim() == undefined || message.trim() == '' || message.trim() == null) ){
      this.messageError= true;
      return;
    }else{
      this.messageError= false;
    }


    var res = message.replace("'", "");
    res =res.replace(/\\/g, '')
    message = res;
    let request ={
      "message" : this.nl2br(message,undefined),
      "id":this.returant_order_number,
      "sender_id":this.logged_user_id,
      "receiver_id":this.reciver_id,       
      "is_media":0, 
      'order_id': this.order_number,     
      'send_type': this.senderType,
      'message_type': this.messageType,  
    }

    this.getDocHeight()
    this.submitted = false; 
          
    if(this.message.nativeElement){
      let now = new Date();  
      this.message.nativeElement.value = '';


      let chatData = {
        "id":this.reciver_id,
        "sender_id":this.logged_user_id,
        "receiver_id":this.reciver_id,
        "message":message,
        "is_media":0,        
        "created_at": now,
        'order_id': this.reciver_id,     
        'send_type': this.senderType,
        'message_type': this.messageType,     
      }; 

      if(this.shortLink){
        chatData.message = this.shortLink;
        chatData.is_media = 1;       
      }
   

      this.messageThreadData.push(chatData); 
      this.scrollToBottom();     
    }


    if(this.shortLink){     
      request.message = this.shortLink;
      request.is_media = 1;
    }

    this.chat.saveMessage(request);

    this.shortLink= undefined;
    
    // this.chatService.saveChatHistory(request).subscribe(res=>{
    //   if(res.status == 'success')
    //   { 
       
    //   }
    //   else {           
    //         this.toastr.errorToastr(res.message, 'Error!', { position: 'bottom-right', showCloseButton: true, animate: 'slideFromTop' });
    //   }
    // });
 
  }

  submitMesaageOnEnter(event,message) {
    this.is_user_type =  false;
    if(this.sendFileData.length == 0 &&  !event.shiftKey && event.keyCode === 13 && (message.trim() == undefined || message.trim() == ''  || message.trim() == null ) ){
      this.submitted =  true;
      this.messageError= true;      
    }else{
      this.messageError= false;
    }

    if(event.keyCode === 13 && !event.shiftKey)
    {
        this.messageSend(message)
    }
 
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
        $this.is_user_type = false 
       
    }, 1000);    
  }

  onEnter(event)
  {    
    // if(event.keyCode === 13)
    // {
    //   this.getUserChat(0)
    // }
  }

  scrollToBottom() {
    setTimeout(() => {
      
      if (document.getElementById('chatInner')) {
        let scroll = document.getElementById('chatInner').scrollHeight;  
        document.getElementById('chatInner').scrollTo(0, scroll);
      }
    }, 500);
  }

  nl2br (str, is_xhtml) {
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
  } 

  br2nl (str, replaceMode) { 

    var replaceStr = (replaceMode) ? "\n" : '';
    // Includes <br>, <BR>, <br />, </br>
    return str.replace(/<\s*\/?br\s*[\/]?>/gi, replaceStr);
    }
  
    ngOnDestroy() {
      this.destroy$.next(true);
      this.destroy$.complete();
      localStorage.removeItem('order_detail');
      if(isPlatformBrowser(this.platformId)){
        var body = document.body;
        body?.classList?.remove("pt-0");       
      }  
    }

    back()
    {

      if(this.returant_order_number){
        this.router.navigate(['/order-tracking'],  { queryParams: { order: btoa(this.order_number)} })
      }
      // if(this.from_page == 'order_history')
      // {
      //   this.router.navigate(['/order-details/'+btoa(this.returant_order_number)])
      // }
      // else
      // {
      //   this.router.navigate(['/order-tracking?order'+btoa(this.returant_order_number)])
      // }
    }


    // On file Select
    onChange(event) {
      this.file = event.target.files[0];
      this.onUpload();
  }

  myEvent() {   
   document.getElementById('custom_image_chat')?.click();
}


    // OnClick of button Upload
    onUpload() {    
      this.chatService.uploadImage(this.file).subscribe(
          (event: any) => {
              if (event.status == 'success') {
                  // Short link via api response
                  this.shortLink = event.data;
                  this.messageSend('image')                  
              }else{
                this.shortLink = undefined;
              }
          }
      );
  }



    
}
