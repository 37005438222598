import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map} from 'rxjs/operators';

@Injectable({
    providedIn:'root'
})

export class NotificationsService {
    apiDomain = `${environment.domain}`;
    notificationSettingApi = 'notifications-settings';
    updateNotificationSettingApi = 'update-notification-setting';
    notificationsApi = 'notifications';
    clearNotificationsApi = 'clear-notifications';
    getUnreadNotificationsApi = 'unread-notifications';
    readAllNotificationsApi = 'read-all-notifications';
    
    // topicsApi = 'topics?name=';
    // faqApi = 'faqs?topic_id=';
    

    // token = JSON.parse(localStorage.getItem('token'));
 
    // httpOptions = {
    //     headers: new HttpHeaders({
    //         'Accept-Language': localStorage.getItem('browserLanguage'),
    //         'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
    //     })        
    //   };

    constructor(private http: HttpClient) { }


    updateNotificationSetting(data){
      const httpOptions = {
        headers: new HttpHeaders({
      
          "Authorization": `Bearer ${JSON.parse(localStorage.getItem('authUser')).token}`
        })
      };

      return this.http.post<any>(this.apiDomain+this.updateNotificationSettingApi,data ,httpOptions).pipe(
        map((res) => {
          return res;
        })
      );
    }
    getNotificationSetting(){
      const httpOptions = {
        headers: new HttpHeaders({
      
          "Authorization": `Bearer ${JSON.parse(localStorage.getItem('authUser')).token}`
        })
      };
      return this.http.get<any>(this.apiDomain+this.notificationSettingApi , httpOptions).pipe(
        map((res) => {
          return res;
        })
      );
    }

    getNotifications(){
      const httpOptions = {
        headers: new HttpHeaders({
      
          "Authorization": `Bearer ${JSON.parse(localStorage.getItem('authUser')).token}`
        })
      };
        return this.http.get<any>(this.apiDomain+this.notificationsApi , httpOptions).pipe(
          map((res) => {
            return res;
          })
        );
      }

      clearNotifications(){
        const httpOptions = {
          headers: new HttpHeaders({
        
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem('authUser')).token}`
          })
        };
        return this.http.delete<any>(this.apiDomain+this.clearNotificationsApi , httpOptions).pipe(
          map((res) => {
            return res;
          })
        );
      }

      getUnreadNotifications(){
        const httpOptions = {
          headers: new HttpHeaders({
        
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem('authUser')).token}`
          })
        };
        return this.http.get<any>(this.apiDomain+this.getUnreadNotificationsApi , httpOptions).pipe(
          map((res) => {
            return res;
          })
        );
      }
      readAllNotifications(){
        const httpOptions = {
          headers: new HttpHeaders({
        
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem('authUser')).token}`
          })
        };
        return this.http.get<any>(this.apiDomain+this.readAllNotificationsApi , httpOptions).pipe(
          map((res) => {
            return res;
          })
        );
      }

      



      

      // getTopics(name){
      //   return this.http.get<any>(this.apiDomain+this.topicsApi+name , this.httpOptions).pipe(
      //     map((res) => {
      //       return res;
      //     })
      //   );
      // }


      // getFaqs(id){
      //   return this.http.get<any>(this.apiDomain+this.faqApi+id , this.httpOptions).pipe(
      //     map((res) => {
      //       return res;
      //     })
      //   );
      // }
      

      

    
    
}