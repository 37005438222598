<!-- <router-outlet name="header" ></router-outlet> -->
<div class="overlay" style="display: none;"></div>
<div class="body-container grey-bg">

<div class="home-separator-wrap d-block mb-0">
    <div class="dashChatInner" id="dashChatInner">       
        <div class="dashChatChatting">
            <div class="chat-header py-2 px-lg-4" id="chatHeader">
                <div class="container-xxl"  *ngIf="returant_order_number && resturant_name">

                    <div class="row align-items-center" >
                        <!-- Chat photo --> 
                        <div class="col-12 col-xl-12">
                            <div class="media text-left align-items-center">
                                <div class="mr-2">
                                   <div class="chatToggler" id="chatToggler" (click)="back()">
                                        
                                        <i class="far fa-angle-left"></i>
                                   </div>
                                </div>

                                <div class="media-body">
                                    <span *ngIf="returant_order_number">ORDER ID: {{returant_order_number}}</span>
                                    <h6 class="text-truncate" *ngIf="resturant_name">{{resturant_name}}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dashChatChattingInner" id="chatInner" *ngIf="messageThreadData.length > 0">
                <div class="message" 
                [ngClass]="{ 'message-right': logged_user_id == chat.sender_id }"
					*ngFor="let chat of messageThreadData">
                    <!-- <a class="avatar avatar-sm mr-2 mr-lg-2" href="javascript:void(0);">
                        <img class="avatar-img" src="assets/assets/img/user.png" alt="">
                    </a> -->
                    <div class="message-body">
                        <div class="message-row">
                            <div class="d-flex align-items-center"
                            [ngClass]="{
                                'justify-content-end': logged_user_id == chat.sender_id
                            }">
                                <div class="message-content-block"
                                >
                                    <div class="message-content">
                                        <!-- <h6 class="mb-2">Alex Thomas</h6> -->
                                        <div *ngIf="chat?.is_media == 0" [innerHTML]="nl2br(chat?.message,'undefind')"></div>
                                        <img style="max-width:100px; width:100%" data-toggle="modal" data-target="#imagemodal" (click)="currentImage = chat?.message" *ngIf="chat?.is_media == 1" [src]="chat?.message">
                                    </div>
                                    <div class="mt-1 text-right">
                                        <small class="opacity-65" *ngIf="chat.created_at !=='Just now'">{{chat.created_at | dateAgo}}</small>
                                        <small class="opacity-65" *ngIf="chat.created_at =='Just now'">Just now</small>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <div class="dashChatChattingInner" id="chatInner"  *ngIf="messageThreadData.length ==0">

			</div>
            <div class="chat-footer" id="chatFooter">
                <span class="" [ngClass]="(is_user_type)?'typing-dots':'d-none'" ><span>.</span><span>.</span><span>.</span></span>
                <div class="container-xxl">
                    <form id="chat-id-1-form">
                        <div class="form-row align-items-center">
                            <div class="col">
                                <div class="input-group">

                                    <!-- Textarea -->
                                    <textarea id="chat-id-1-input"  #message
                                    [ngClass]="{'empty-message-error': submitted && messageError }"
                                    class="form-control border-0" 
                                    placeholder="Type your message..." 
                                    rows="1" data-autosize="true"
                                    (keyup)="submitMesaageOnEnter($event ,message.value)"
                                    (keydown.enter)="$event.preventDefault();"></textarea>
 
                                    <!-- Emoji button -->
                                    <!-- <div class="input-group-append">
                                        <button class="btn btn-secondary btn-minimal bg-transparent border-0"
                                            type="button">
                                            <span class="ion-android-happy"></span>
                                        </button>
                                    </div> -->

                                    <!-- Upload button -->

                                                          
                      
<!-- 
                                    <div class="input-group-append" *ngIf="messageType == 'customer_shopper'">
                                        <button id="chat-upload-btn-1"
                                            class="btn btn-secondary btn-minimal bg-transparent border-0 pr-0"
                                            type="button">
                                            <input  class="form-control d-none" type="file"  id="custom_image_chat" 
                                            (change)="onChange($event)"> 

                                            <img (click)="myEvent()" style="max-width: 40px;" src="assets/img/camera-icon.png"/>
                                        </button>
                                    </div> -->
                                </div>
                            </div>
                            <!-- Submit button -->
                            <div class="col-auto">
                                <div class="actionchat-icon">
                                <div class="input-group-append" *ngIf="messageType == 'customer_shopper'">
                                    <button id="chat-upload-btn-1"
                                        class="btn btn-ico btn-minimal border-0"
                                        type="button">
                                        <input  class="form-control d-none" type="file"  id="custom_image_chat" 
                                        (change)="onChange($event)"> 

                                        <i (click)="myEvent()" class="fas fa-camera"></i>
                                    </button>
                                </div>
                                <button class="btn btn-ico" type="submit" (click)="messageSend(message.value)">
                                    <!-- <img src="assets/img/send-msg.png"/> -->
                                    <i class="fas fa-paper-plane"></i>
                                </button>
                                </div>
                            </div>

                        </div>

                    </form>

                </div>
            </div>
        </div>
    </div>
    </div>
</div>




<!-- Modal -->
<div class="modal fade img-modal" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header py-0">
          <!-- <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> -->
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body text-center">
         <img *ngIf="currentImage" class="img-fluid" src="{{currentImage}}">
        </div>        
      </div>
    </div>
  </div>
