import { Injectable } from '@angular/core'
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import {map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import {environment} from '../../environments/environment';
import * as globalConstant from '../shared/global'; 

@Injectable({
    providedIn: 'root'
})
 
export class CmsService {

	
	apiDomain				        =	`${environment.domain}`;
	privacyPolicyApi                =   globalConstant.PRIVACY_POLICY_API;
	termConditionsApi               =   globalConstant.TERMS_CONDITIONS_API;
	refundPolicyApi                 =   globalConstant.REFUND_POLICY_API;
	aboutUsApi                      =   globalConstant.ABOUT_US_API;
	faqApi                          =   globalConstant.FAQ_API;
	footerLinkApi              		=   globalConstant.FOOTER_LINKS_API;
	contactInfoApi                  =   globalConstant.CONTACT_INFO_API;
	
	
    constructor(
		public router: Router,
		private http: HttpClient
	){} 

    privacyPolicy(){
		
		return this.http.get<any>(this.apiDomain+this.privacyPolicyApi).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
          }));
	}


	term(){
		
		return this.http.get<any>(this.apiDomain+this.termConditionsApi).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
          }));
	}

	refundPolicy(){
		
		return this.http.get<any>(this.apiDomain+this.refundPolicyApi).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
          }));
	}

	getAboutUsContent(){
		return this.http.get<any>(this.apiDomain+this.aboutUsApi).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
        }));
	}

	getFaq(){
		return this.http.get<any>(this.apiDomain+this.faqApi).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
        }));
	}


	footer(){


		return this.http.get<any>(this.apiDomain+this.footerLinkApi).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
          }));
	}
	
	getContactInfo(){
		return this.http.get<any>(this.apiDomain+this.contactInfoApi).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
          }));
	}
}
