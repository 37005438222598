import { Injectable } from '@angular/core'
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import {map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import {environment} from '../../environments/environment';
import * as globalConstant from '../shared/global'; 

@Injectable({
    providedIn: 'root'
})

export class HomeService {

	
	apiDomain	 			        	=	`${environment.domain}`;
	joinNewsletterApi                	=   globalConstant.JOIN_US_API;
	unsubscribeNewsletterApi        	=   globalConstant.UNSUBSCRIBED_NEWSLETTER_API;
	categoryApi                			=   globalConstant.CATEGORYS_API;
	brandApi                			=   globalConstant.BRANDS_API;
	storeApi                			=   globalConstant.STORES_API;
	homeApi                	            =   globalConstant.HOME_API;
	newArrivalsApi                      =   globalConstant.NEW_ARRIVALS_API;
	updateAdClickApi                    =   globalConstant.UPDATE_ADD_CLICK_API;
	lifeStylesApi                    	=   globalConstant.LIFE_STYLES_API;
	
	
    constructor(
		public router: Router,
		private http: HttpClient
	){} 

    newsLetter(data){
		
		return this.http.post<any>(this.apiDomain+this.joinNewsletterApi,data).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
          }));
	}


	unSubscribedNewsletter(data){
		
		return this.http.get<any>(this.apiDomain+this.unsubscribeNewsletterApi+data).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
          }));
	}


	category(){
		
		return this.http.get<any>(this.apiDomain+this.categoryApi).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
          }));
	}

	
	
	brands(stores){
		
		return this.http.get<any>(this.apiDomain+this.brandApi+(stores ?'?store_ids='+stores  : '')	).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
          }));
	}


	store(latitude,longitude){
		
		return this.http.get<any>(this.apiDomain+this.storeApi+latitude+'&longitude='+longitude).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
          }));
	}

	home(){
		return this.http.get<any>(this.apiDomain+this.homeApi).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
        }));
	}

	getNewArrivals(latitude,longitude){
		return this.http.get<any>(this.apiDomain+this.newArrivalsApi+latitude+'&longitude='+longitude).pipe(map(res=>{
			return res;
        }),
        catchError(err => { 
            return err.error;
        }));
	}
	
	getUpdateAdClick(ad_id){
		return this.http.get<any>(this.apiDomain+this.updateAdClickApi+ad_id).pipe(map(res=>{
			return res;
        }),
        catchError(err => { 
            return err.error;
        }));
	}

	lifeStyles(){
		return this.http.get<any>(this.apiDomain+this.lifeStylesApi).pipe(map(res=>{
			return res;
        }),
        catchError(err => { 
            return err.error;
        }));
	}
}
