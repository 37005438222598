
import { NavigationEnd, NavigationStart, Router, ActivatedRoute ,Params} from '@angular/router';
import { Component, DoCheck, OnInit , ViewChild, ElementRef, NgZone, TemplateRef} from '@angular/core';
import { HeaderService } from '../../services/header.service'
import { HomeService } from '../../services/home.service'
import { CartService } from '../../services/cart.service'
import { MessageService } from '../../message.service';
import { NotificationsService } from '../../services/notifications.service'
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Location } from '@angular/common';
import { WishlistService } from 'src/app/services/wishlist.service';
import { MapsAPILoader } from '@agm/core';
import { google } from "google-maps";
import { ModalDirective } from 'ngx-bootstrap/modal';
import { abort } from 'process'; 
// import { MapsAPILoader } from '@agm/core';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, DoCheck {
  
  card_popup_opean : any = 'close'
  authuser:any;
  wishlistCount:number;
  cartId:any;
  menuShow: boolean = false;
  isAuthenticate:boolean = false;
  latitude: any = '';
  longitude: any = '';
  zoom: number;
  address: string = '';
  private geoCoder;
  categorys : any  =[]
  currentUrl : any = ''
  cart_detail : any = []
  sub_total : any = 0
  delivery_charges : any = 0
  total_saving : any = 0
  delivery_charges_msg : any = ''
  cart_count : any = 0
  quick_buy_product:any = [];

  category_id : any = ''
  sub_category_id : any = ''
  sortBy : any = ''
  price : any = ''
  brand_list_ids : any  = ''
  store_list_ids : any = ''
  life_style_list_ids : any = ''
  modalRef: BsModalRef;
  // location_id :any = localStorage.getItem('location_id')
  loacations : any = []
  kayword :any = ''
  is_last_minuts_buys : any = 0
  // @ViewChild('search')
  // public searchElementRef: ElementRef;
  locationColor: boolean = false;
  select_location_id : any;
  is_clicked : boolean = false;
  @ViewChild('location_search')
  public searchElementRef: ElementRef;
  @ViewChild('locationmodal',{static:false}) public locationmodal:ModalDirective;
  is_address_search : boolean  = false
  is_address_include : boolean = false;
  current_address : any = ''
  cateShow : boolean = false;
  header_marketing_text : any = ''
  notifications :any = []
  notification_count : any = 0;
  constructor(private router: Router,
    private headerService :HeaderService,
    private homeService :HomeService,
    private cartService :CartService,
    private messageService :MessageService,
    private toastr :ToastrManager,
    private activatedRoute: ActivatedRoute,
    private modalService: BsModalService,
    private location_path :Location,
    private wishlistService: WishlistService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private notificationService :NotificationsService) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.menuShow = false;
      } else if (event instanceof NavigationEnd) {
        this.currentUrl =  this.router.url.split('?')[0]
        
      }
    });    
    this.activatedRoute.queryParams.subscribe(params => {
      this.category_id = params['category'] ?atob(params['category']) : '';
      this.sub_category_id = params['sub_category'] ?atob(params['sub_category']) : '';
      this.sortBy = params['sort_by'] ?atob(params['sort_by']) : '';
      this.price = params['price'] ?atob(params['price']) : '';
      this.brand_list_ids = params['brand'] ?atob(params['brand']) : '';
      this.store_list_ids = params['store'] ?atob(params['store']) : '';
      this.kayword = params['name'] ?atob(params['name']) : '';
      this.life_style_list_ids = params['life_styles'] ?atob(params['life_styles']) : '';
    });
    this.messageService.getMessage().subscribe(message => {
		
      if(message == 'true')
      {
        this.gcardpopup('open') 
      }
    }); 

    this.messageService.getCategoryMessage().subscribe(message => {
		
      if(message)
      {
       
        this.category_id =  message.category
        this.sub_category_id = message.sub_category
        this.sortBy = message.sort_by
        this.price = message.price
        this.brand_list_ids = message.brand
        this.store_list_ids = message.store,
        this.life_style_list_ids = message.life_styles
      }
    });

    this.messageService.getUpdateProfileMessage().subscribe(message => {
		
      if(message == '1')
      {
        this.authuser = JSON.parse(localStorage.getItem('authUser')).data;
      }
    
    
    });

    this.messageService.getCategoryToggleMessage().subscribe(message => {
      // console.log(message);
      this.cateShow = message
    });
  } 
 
  ngDoCheck(){
    if(localStorage.getItem('authUser')){
      // this.getWishlistProductsCount();
      this.isAuthenticate = true;
      let authuser = JSON.parse(localStorage.getItem('authUser'));
      this.authuser = authuser.data;      
    }else{
      this.isAuthenticate = false;
    }
 
  
  }
  
  ngOnInit(): void {

    this.messageService.getCartDetails().subscribe(res=>{
      if(res == '1'){
        // this.getCartDetail();
        // this.getCartCount();

        this.getCartCount().then(res =>   this.getCartDetail());
      }
    })
   if(localStorage.getItem('authUser') )
   {
    
    this.getUnreadNotificationsCount()
    this.getCartCount();
    if((localStorage.getItem('latitude')  && localStorage.getItem('latitude')!== '' && localStorage.getItem('latitude')!== null  && localStorage.getItem('latitude')!== undefined) && (localStorage.getItem('longitude')  && localStorage.getItem('longitude')!== '' && localStorage.getItem('longitude')!== null  && localStorage.getItem('longitude')!== undefined))
    {
      this.current_address = localStorage.getItem('current_location')
      this.latitude = localStorage.getItem('latitude')
      this.longitude =  localStorage.getItem('longitude')
      document.getElementById("search_location").setAttribute('value',this.current_address );
      this.getCartDetail();
    }
   }

   if(!localStorage.getItem('latitude') && !localStorage.getItem('longitude'))
   {
    setTimeout(()=>
    { 
     this.locationmodal.show()
    }, 1000);
   }
   else
   {
    if((localStorage.getItem('latitude')== '' || localStorage.getItem('latitude')== null  || localStorage.getItem('latitude')== undefined) && (localStorage.getItem('longitude')== '' || localStorage.getItem('longitude')== null  || localStorage.getItem('longitude')== undefined))
    {
      setTimeout(()=>
      { 
       this.locationmodal.show()
      }, 1000);
    }
    else
    {
      this.current_address = localStorage.getItem('current_location')
      this.latitude = localStorage.getItem('latitude')
      this.longitude =  localStorage.getItem('longitude')
      document.getElementById("search_location").setAttribute('value',this.current_address );
    }
   }



  this.headerService.headerText().subscribe(res=>{
    this.header_marketing_text = res.header_marketing_text
  });

  //  this.getLocations()
  //  this.getCategory()
 
   this.mapsAPILoader.load().then(() => {
    this.geoCoder = new google.maps.Geocoder;
    // this.setCurrentLocation();
    this.autoLocation();
  });
 
  //  this.autoLocation()
  
  }

  getCategory()
  {
    this.homeService.category().subscribe(res => {
      if (res.status == 'success') {
        this.categorys = res.data;
       
      }
      else
      {
        this.categorys = []
      }
    });
  }
  autoLocation(){
 
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      // this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.setComponentRestrictions({
        country: ["ZA"],
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
           this.address = place.formatted_address;            
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });
  }
 

  menuToggle() {
    this.menuShow = !this.menuShow;
  }

  catToggle()
  {
    this.cateShow = false;
    this.messageService.sendCatRemoveClassMessage('1')
  }
 
  gcardpopup(value)
  { 
    this.is_clicked = true;
    if(localStorage.getItem('authUser'))
    {
      this.getCartCount().then(res =>   
        this.getCartDetail().then(res1=>{
          var element =  document.querySelector("body")
          if(element)  
          element.classList.add('cart-popup-body');
          this.card_popup_opean = value
          localStorage.setItem('add_item','0')
          this.is_clicked = false;
        })  
      );      
      

      // this.getCartDetail()
      // this.getCartCount()



    }
    else
    {
      this.router.navigate(['/login'])
    }

  }
 
  gcardpopupclose(value)
  {
    var element =  document.querySelector("body")
    if(element)  
    element.classList.remove('cart-popup-body');
    this.card_popup_opean = value
  }

  logout(){
    localStorage.removeItem('authUser');
    localStorage.removeItem('longitude');
    localStorage.removeItem('latitude');
    localStorage.removeItem('current_location');

    // this.router.navigate(['/login']);
    this.router.navigate(['/login'],{ queryParams: { returnUrl: this.router.url } });
  }

  getLocations()
  {
    this.headerService.location().subscribe(res=>{
      if(res.status == "success"){
        this.loacations = res.data
      }
      else
      {
        this.loacations = []
      }
    });
  }

 
  getCartDetail()
  {
    return new Promise((resolve, reject) => {
      this.cartService.cart(this.latitude,this.longitude).subscribe(res=>{
        if(res.status == "success"){
         
          this.cart_detail = res.data
          this.quick_buy_product = res.quick_buy_product;
          this.sub_total = res.sub_total
          this.delivery_charges = res.delivery_charges
          this.total_saving = res.total_saving
          this.delivery_charges_msg = res.delivery_charges_msg;
          let restaurentLocationId = res?.['data'][0]?.location_id ?  res?.['data'][0]?.location_id : null;
          this.messageService.sendRestaurentLocation(restaurentLocationId);
          resolve(this.cart_detail)
        }
        else
        {
          this.cart_detail = [];
          this.quick_buy_product = [];
          resolve(this.cart_detail)
        }
      });
    });
  } 
  
  getCartCount()
  {
    return new Promise((resolve, reject) => {
      this.cartService.cartCount().subscribe(res=>{
        if(res.status == "success"){
          if(res.data > 0)
          {
            this.cart_count =  res.data
            resolve(this.cart_count)
          }
          else
          {
            this.cart_count =  0
            resolve(this.cart_count)

          }
        }
      });
    });
  }

  decreaseQuantity(quantity,id,product_id,product_variant_id,unit_id)
  {
    if(quantity > 0 )
    {
      quantity -=1;
    }

    this.updateCart(product_id,product_variant_id,unit_id,quantity,id)

  }

  increaseQuantity(quantity,id,product_id,product_variant_id,unit_id)
  {
    if(quantity > 0 )
    {
      // if(quantity > 49)
      // {
      //   this.toastr.errorToastr("You can't add quantity more than 50.",'Hey!')
      //   return
      // }
      quantity +=1;
    }


    this.updateCart(product_id,product_variant_id,unit_id,quantity,id)
  }

  updateCart(product_id,product_variant_id,unit_id,quantity,cart_id)
  {
    this.cartService.update_cart(product_id,product_variant_id,unit_id,quantity,cart_id).subscribe(res=>{
      if(res.status == "success"){
        this.messageService.sendCartDetails('1');
        // this.getCartDetail()
        // this.getCartCount()

      this.getCartCount().then(res =>   this.getCartDetail());
      }
      else if(res.status == 'error')
      {
        this.toastr.errorToastr(res.msg,'Error!')
      }
      else
      {
        this.toastr.errorToastr(res.msg,'Error!')
      }
    });

  }

  openRemoveItem(template: TemplateRef<any>, cartId){
      this.cartId = cartId;
      this.modalRef = this.modalService.show(template , { class: 'modal-dialog-centered confirmPopup' } );
  }

  removeFromCart(){

    this.cartService.removeFromCart(this.cartId).subscribe((res)=>{
      if(res.status == 'success'){
        this.getCartCount().then(res =>   this.getCartDetail());
        this.modalRef.hide();
        // this.toastr.successToastr(res.msg, 'Success');
        // this.getCartDetail();
        // this.getCartCount()
      

      }else{
        this.modalRef.hide();
        this.toastr.errorToastr(res.msg, 'Error');
      }
    })
  }

  selectCategory(category_id)
  {
    let url = '';
    if(category_id == this.category_id)
    {
      url = '/products?category='+btoa(category_id)+'&sub_category='+btoa(this.sub_category_id)+'&sort_by='+btoa(this.sortBy)+'&price='+btoa(this.price)+'&brand='+btoa(this.brand_list_ids)+'&store='+btoa(this.store_list_ids)+'&name='+btoa(this.kayword)+'&life_styles='+btoa(this.life_style_list_ids)
    }
    else
    {
      url = '/products?category='+btoa(category_id)+'&sub_category='+''+'&sort_by='+btoa(this.sortBy)+'&price='+btoa(this.price)+'&brand='+btoa(this.brand_list_ids)+'&store='+btoa(this.store_list_ids)+'&name='+btoa(this.kayword)+'&life_styles='+btoa(this.life_style_list_ids)
    }
    
    this.router.navigateByUrl(url)

    // document.getElementById('navCatContent').remove('show')
    var element =  document.getElementById("navCatContent")
    if(element)  
    element.classList.remove('show');
  }



  selectSubCategory(category_id,sub_category_id)
  {
   
    let url = '/products?category='+btoa(category_id)+'&sub_category='+btoa(sub_category_id)+'&sort_by='+btoa(this.sortBy)+'&price='+btoa(this.price)+'&brand='+btoa(this.brand_list_ids)+'&store='+btoa(this.store_list_ids)+'&name='+btoa(this.kayword)+'&life_styles='+btoa(this.life_style_list_ids)
    this.router.navigateByUrl(url)
  }

  productList()
  {
    if(this.router.url.split('?')[0] !=='/products')
    {
      let url = '/products?category='+btoa(this.category_id)+'&sub_category='+btoa(this.sub_category_id)+'&sort_by='+btoa(this.sortBy)+'&price='+btoa(this.price)+'&brand='+btoa(this.brand_list_ids)+'&store='+btoa(this.store_list_ids)+'&name='+btoa(this.kayword)+'&life_styles='+btoa(this.life_style_list_ids)
      this.router.navigateByUrl(url)
    }

  }

  select_location(location_id)
  {
    localStorage.setItem('location_id',location_id)
    if(this.currentUrl == '/products')
    {
      this.messageService.sendLocationMessage(location_id)
      
    }
    else
    {
      let url = '/products?category='+btoa(this.category_id)+'&sub_category='+btoa(this.sub_category_id)+'&sort_by='+btoa(this.sortBy)+'&price='+btoa(this.price)+'&brand='+btoa(this.brand_list_ids)+'&store='+btoa(this.store_list_ids)+'&name='+btoa(this.kayword)+'&life_styles='+btoa(this.life_style_list_ids)
      this.router.navigateByUrl(url)
  
    }
    
    
  }


  onKeyUp(event,value)
  {
    if(event.keyCode === 13)
    {
      this.searchItem(value)
    }
  }

  searchItem(iteam)
  {
   // localStorage.setItem('search_iteam' , iteam)
    this.cateShow = false;
    if(this.currentUrl !== '/products')
    {
      let url = '/products?category='+btoa(this.category_id)+'&sub_category='+btoa(this.sub_category_id)+'&sort_by='+btoa(this.sortBy)+'&price='+btoa(this.price)+'&brand='+btoa(this.brand_list_ids)+'&store='+btoa(this.store_list_ids)+'&name='+btoa(iteam)+'&life_styles='+btoa(this.life_style_list_ids)
      this.router.navigateByUrl(url);
    }
    else
    { 

      this.location_path.replaceState('/products?category='+btoa(this.category_id)+'&sub_category='+btoa(this.sub_category_id)+'&sort_by='+btoa(this.sortBy)+'&price='+btoa(this.price)+'&brand='+btoa(this.brand_list_ids)+'&store='+btoa(this.store_list_ids)+'&name='+btoa(iteam)+'&life_styles='+btoa(this.life_style_list_ids))
      this.messageService.sendSearchItemMessage(iteam)
    }
    

  }

  addToCart(product_id ,quantity,el)
  { 
    localStorage.setItem('add_item','1')
    if(localStorage.getItem('authUser'))
    {
      var DropdownList = (document.getElementById(el)) as HTMLSelectElement;
      var SelectedIndex = DropdownList.value
      let product_variant_id =SelectedIndex.split('-')[0]
      let unit_id =SelectedIndex.split('-')[1]
     
      if(quantity == '' || quantity == null || quantity == undefined)
      {
        this.toastr.errorToastr('Please enter quantity first','Error!')
        return 
      }
      else
      {
        if(quantity <= 0)
        {
          this.toastr.errorToastr('Quantity should not be less than or equal to 0.','Error!')
          return
        }
        // if(quantity > 50)
        // {
        //   this.toastr.errorToastr("You can't add quantity more than 50.",'Hey!')
        //   return
        // }
        this.cartService.add_to_cart(product_id,product_variant_id,unit_id,quantity).subscribe(res => {
          if (res.status == 'success') {
            // this.toastr.successToastr(res.msg,'Success!')
 
            if(this.quick_buy_product.length > 0)
            {
              if(this.quick_buy_product.length ==1)
              {
                this.is_last_minuts_buys= 0
              }
              else
              {
                this.is_last_minuts_buys= 1
              }
            }
            else
            {
              this.is_last_minuts_buys= 0
            }

            
            this.messageService.sendMessage('true')
          }
          else if(res.status == 'error')
          {
            this.toastr.errorToastr(res.msg,'Error!')
          }
          else
          {
            this.toastr.errorToastr(res.msg,'Error!')
          }
        });
      }
    }
    else
    {
      this.router.navigate(['/login']) 
    }
  }

  // getWishlistProductsCount(){
  //   this.wishlistService.getWishlistCount().subscribe(count=>{
  //     if(count){
  //       this.wishlistCount = count; 
  //     }else{
  //       this.wishlistCount = 0;
  //     }      
  //   });
  // }

  lastminutbuy()
  {
    if(this.is_last_minuts_buys ==0)
    {
      this.is_last_minuts_buys = 1
    }
    else
    {
      this.is_last_minuts_buys = 0
    }
  }

  navToLogin(){
    const snapshot = this.router.routerState.snapshot.url;
    this.router.navigate(['/login'], { queryParams: { returnUrl: snapshot } })
  }

  numberOnly(event): boolean {
    
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode!==46) {
      return false;
    }
    return true;

  }

   // Get Current Location Coordinates
   setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.locationColor =  !this.locationColor;
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  getAddress(latitude, longitude) {
  
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {

    
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          var str = results[0].formatted_address;
          this.address = str.replace("Unnamed Road,", "");
          this.searchElementRef.nativeElement.value =this.address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  addAddress(current_address)
  {
    this.is_address_search = false;
    this.is_address_include =  false;
    if(current_address == '' || current_address == undefined || current_address == null)
    {
      this.is_address_search =  true;
      return
    }
    else
    {

      if((this.latitude== '' || this.latitude== null  || this.latitude== undefined) && (this.longitude== '' || this.longitude== null  || this.longitude== undefined))
      { 
        this.toastr.errorToastr('There are no malls in your area. Please try a different location!','Error!')
        return;
      }
      this.homeService.store(this.latitude,this.longitude).subscribe(res => {
        if (res.status == 'success') {
          // this.stores = res.data;
          if(res.data.length > 0)
          {
            this.current_address = current_address
            localStorage.setItem('current_location',current_address)
            localStorage.setItem('latitude',this.latitude)
            localStorage.setItem('longitude',this.longitude) 
            this.messageService.sendSetLocationMessage('true')
            if(localStorage.getItem('authUser'))
            {
              this.getCartDetail()
            }
            this.locationmodal.hide()
          }
          else
          {
            this.latitude = ''
            this.longitude =''
            localStorage.setItem('current_location','')
            localStorage.setItem('latitude','')
            localStorage.setItem('longitude','')
            this.toastr.errorToastr('we can not find any malls near by you.','Error!')
          }
        }
        else
        {
          this.latitude = ''
          this.longitude =''
          localStorage.setItem('current_location','')
          localStorage.setItem('latitude','')
          localStorage.setItem('longitude','')
          this.toastr.errorToastr('we can not find any malls near by you.','Error!')
        }
      });
      // for(let i =0 ; i < this.loacations.length ; i++)
      // {
      //   if(current_address.includes(this.loacations[i].name))
      //   {
      //     this.select_location_id = this.loacations[i].id
      //     this.is_address_include =  true;
      //     break;
      //   }
      // }

      // if(this.is_address_include == true)
      // {
      //   this.location_id = this.select_location_id
      //   localStorage.setItem('location_id',this.location_id)
      //   localStorage.setItem('latitude',this.latitude)
      //   localStorage.setItem('longitude',this.longitude)
      //   if(localStorage.getItem('authUser'))
      //   {
      //     this.getCartDetail()
      //   }
      //   this.messageService.sendSetLocationMessage('true')
      //   this.locationmodal.hide()
      // }
      // else
      // {
      //   this.toastr.errorToastr('Nearest location not found.','Error!')
      // }
    }
  }

  seeproductdetail(id){
    this.router.navigate(['/product-detail'], { queryParams: { product: btoa(id) } });
    this.gcardpopupclose('close');
  }

  changeLocation()
  {
    this.locationmodal.show()
  }

  resetLatLong()
  {
    this.latitude = ''
    this.longitude = ''
  }

  getNotifications()
  {
    this.notificationService.getNotifications().subscribe(res =>{
      if(res.status == 'success')
      {
        this.notifications = res.data
        if(this.notification_count > 0)
        {
          this.notificationService.readAllNotifications().subscribe(res1 =>{
            if(res1.status == 'success')
            {
              for(let i=0;i<this.notifications.length;i++)
              {
                this.notifications[i].is_read = 1
              }
              this.notification_count = 0
            }
          });
        }
      }
      else
      {
        this.notifications =  []
      }
    });
  }

  getUnreadNotificationsCount()
  {
    this.notificationService.getUnreadNotifications().subscribe(res =>{
      if(res.status == 'success')
      {
        this.notification_count = res.data
      }
      else
      {
        this.notification_count =  0
      }
    });
  }

  unread_notifications()
  {
    if(localStorage.getItem('authUser'))
    this.getNotifications()
    
   
  }
 
  onMouseHover()
  {
    if(localStorage.getItem('authUser'))
    {
      if(this.notification_count > 0 && this.notifications.length> 0)
      {
        this.notificationService.readAllNotifications().subscribe(res =>{
          if(res.status == 'success')
          {
            for(let i=0;i<this.notifications.length;i++)
            {
              this.notifications[i].is_read = 1
            }
            this.notification_count = 0
          }
        });
      }
      else 
      {
        if(this.notifications.length == 0)
        this.getNotifications()
      }
    }
    
  }
}

