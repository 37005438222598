import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forget-pwd-layout',
  templateUrl: './forget-pwd-layout.component.html',
  styleUrls: ['./forget-pwd-layout.component.css']
})
export class ForgetPwdLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  scrollTop(event) {
    window.scroll(0, 0); 
  }

}
