import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MessageService {
    private subject = new Subject<any>();
    private sendcategorysubject = new Subject<any>();
    private updatelocationsubject = new Subject<any>();
    private searchItemsubject = new Subject<any>();
    private updateprofilesubject = new Subject<any>();
    private setlocationSubject = new Subject<any>();
    private categoryTogleSubject = new Subject<any>();
    private catremoveClassSubject = new Subject<any>();
    private cartDetails = new Subject<any>();
    private currentRestaurentLocation = new BehaviorSubject<number>(null);
    private update_title_subject = new Subject<any>();


    sendRestaurentLocation(id: number) {
        this.currentRestaurentLocation.next( id );
    }

    getRestaurentLocation() {
        return this.currentRestaurentLocation.asObservable();
    }

   clearRestaurentLocation(id: number) {
        this.currentRestaurentLocation.next(null);
    }

    sendMessage(message: string) {
        this.subject.next( message );
    }

    clearMessage() {
        this.subject.next();
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }


    sendCategoryMessage(message: any) {
        this.sendcategorysubject.next( message );
    }

    clearCategoryMessage() {
        this.sendcategorysubject.next();
    }

    getCategoryMessage(): Observable<any> {
        return this.sendcategorysubject.asObservable();
    }
   

    sendLocationMessage(message: any) {
        this.updatelocationsubject.next( message );
    }

    clearLocationMessage() {
        this.updatelocationsubject.next();
    }

    getLocationMessage(): Observable<any> {
        return this.updatelocationsubject.asObservable();
    }


    sendSearchItemMessage(message: any) {
        this.searchItemsubject.next( message );
    }

    clearSearchItemMessage() {
        this.searchItemsubject.next();
    }

    getSearchItemMessage(): Observable<any> {
        return this.searchItemsubject.asObservable();
    }


    sendUpdateProfileMessage(message: any) {
        this.updateprofilesubject.next( message );
    }

    clearUpdateProfileMessage() {
        this.updateprofilesubject.next();
    }

    getUpdateProfileMessage(): Observable<any> {
        return this.updateprofilesubject.asObservable();
    }


    sendSetLocationMessage(message: any) {
        this.setlocationSubject.next( message );
    }

    clearSetLocationMessage() {
        this.setlocationSubject.next();
    }

    getSetLocationMessage(): Observable<any> {
        return this.setlocationSubject.asObservable();
    }


    sendCategoryToggleMessage(message: any) {
        this.categoryTogleSubject.next( message );
    }

    clearCategoryToggleMessage() {
        this.categoryTogleSubject.next();
    }

    getCategoryToggleMessage(): Observable<any> {
        return this.categoryTogleSubject.asObservable();
    }
    
    sendCatRemoveClassMessage(message: any) {
        this.catremoveClassSubject.next( message );
    }

    clearCatRemoveClassMessage() {
        this.catremoveClassSubject.next();
    }

    getCatRemoveClassMessage(): Observable<any> {
        return this.catremoveClassSubject.asObservable();
    }

    sendCartDetails(message:any){
        this.cartDetails.next(message);
    }

    clearCartDetails(){
        this.cartDetails.next();
    }

    getCartDetails():Observable<any>{
        return this.cartDetails.asObservable();
    }
 
    
    onInputChange(event) {
		let newVal = event.replace(/\D/g, '');
	
		// if (backspace && newVal.length <= 6) {
	 
		//   newVal = newVal.substring(0, newVal.length - 1);
		// }
		if (newVal.length === 0) {
		  newVal = '';
		} else if (newVal.length <= 3) {
		  newVal = newVal.replace(/^(\d{0,3})/, '$1');
		} else if (newVal.length <= 6) {
		  newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '$1$2');
		} else if (newVal.length <= 10) {
		  
		  newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1$2$3');
		} else {
		  newVal = newVal.substring(0, 10);
		  newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1$2$3');
		}
    
		if(newVal.length == 9)
		{
		 newVal = newVal.replace(newVal, '+27'+newVal);
		}
	
		return newVal;
	}

    getUpdateTitleMessage(): Observable<any> {
        return this.update_title_subject.asObservable();
    }
    sendUpdateTitleMessage(message: any) {
        this.update_title_subject.next( message );
    }

    clearUpdateTitleMessage() {
        this.update_title_subject.next();
    }

}
