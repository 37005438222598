export const scrollVal= 0;
export const SITE_TITLE						=	'GROZAP'
export const LOGIN_API = 'login'
export const SITE_TITLE_SEPARATOR			=	' | '
export const PRIVACY_POLICY_API = 'privacy-policy'
export const TERMS_CONDITIONS_API = 'terms-conditions'
export const JOIN_US_API = 'join-us'
export const UNSUBSCRIBED_NEWSLETTER_API = 'unsubscibed/'
export const REFUND_POLICY_API = 'refund-policy'
export const CATEGORYS_API = 'categorys'
export const BRANDS_API = 'brands'
export const STORES_API = 'stores?latitude='
export const SIGNUP_API = 'signup'
export const RESEND_VERIFICATION_API = 'resend-verification-code?user_id='
export const VERIFY_VERIFICATION_CODE_API = 'verify-verification-code?user_id='
export const VERIFICATION_CODE_API = '&verification_code='
export const PASSWORD_VALIDATOR = /^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/
// export const PHONE_NUMBER_VALIDATOR =/^\+27[0-9]{9}$/
export const PHONE_NUMBER_VALIDATOR =/^(\+27|0)[6-8][0-9]{8}$/
export const ABOUT_US_API = 'about-us'
export const HOME_API = 'homepage'
export const NEW_ARRIVALS_API = 'new-arrival-products?latitude='
export const CHANGE_PASSWORD_API = 'change-password' 
export const PRODUCT_DETAIL_API = 'product-info?product_id='
export const RELATED_PRODUCT_API = 'related-products?latitude='
export const ADD_TO_WISHLIST_API = 'add-to-wishlist?product_id='
export const REMOVE_FROM_WISHLIST_API = 'remove-from-wishlist?product_id='
export const WISHLIST_PRODUCTS_API = 'wishlist-products'
export const REMOVE_FROM_CART_API = 'remove-from-cart?cart_id='
export const UPDATE_ADD_CLICK_API = 'update-ad-click?ad_id='
export const FAQ_API = 'faqs'
export const CONTACT_INFO_API = 'contact-info'
export const RECENT_VIEW_API = 'recent-views?page='

export const LOCATIONS_API = 'locations'


export const FORGOT_PASSWORD_API = 'forget-password?email='
export const RESET_PASSWORD_API = 'reset-password/'
export const SOCIAL_LOGIN_API = 'social-login'
export const PRODUCTS_API = 'products?latitude='
export const ADD_TO_CART_API = 'add-to-cart?product_id='
export const DELETE_ALL_CART_API = 'empty-cart'
export const CARTS_API = 'carts?latitude='
export const CART_COUNT_API = 'cart-count'
export const UPDATE_CART_API = 'update-cart?product_id='
export const PRODUCT_ADS_API = 'product-ads'
export const UPDATE_PROFILE_IMAGE_API = 'update-profile-image'
export const EDIT_PROFILE_API = 'edit-profile'
export const FOOTER_LINKS_API = 'footer-links'
export const LIFE_STYLES_API = 'life-styles'
export const HEADER_TEXT_API = 'header-text'
export const ADD_ADDRESS_API = 'addresses/add'
export const EDIT_ADDRESS_API = 'addresses/edit'
export const ADDRESSES_API = 'addresses'
export const DELETE_ADDRESS_API = 'addresses/delete'
export const APPLY_COUPON_CODE = 'apply-coupon-code'
export const CART_LIST_API = 'carts-list'
export const UPDATE_CART_INSTRUCTION_API = 'update-item-checkout-instructions'
export const CHECKOUT_ORDER_SUMMARY_API = 'checkout-order-summary'
export const APPLY_TIP_API = 'apply-tip'
export const CHECKOUT_API = 'checkout'
export const REMOVE_COUPON_CODE = 'remove-coupon-code'
export const GET_ORDER_HISTORY_API = 'orders'
export const GET_TRACK_ORDER_DETAIL_API = 'track-order?order_id='
export const REVIEW_ORDER_ITEM_API = 'review-order-item?order_item_id='
export const GET_TRANSACTIONS_API = 'transactions?page='
export const GET_WALLET_HISTORY_API = 'wallet-histories?page='
export const GET_WALLET_API = 'wallet'
export const ADD_BALANCE_IN_WALLET_API = 'add-balance-in-wallet?amount='
export const ADD_BALANCE_IN_WALLET_PAYMENT_API = 'add-balance-in-wallet-payment'
export const PRODUCT_REVIEWS_API = 'product-reviews/'
export const ACCEPT_REJECT_ORDER_ITEM_API = 'order-item/accept-reject';
export const AVAILABLE_SLOTS = "available-slots";

// export const GET_REVIEWS_API = ''
