import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FrontLayoutComponent } from './components/layout/front-layout/front-layout.component';
import { LoginLayoutComponent } from './components/layout/login-layout/login-layout.component';
import { ForgetPwdLayoutComponent } from './components/layout/forget-pwd-layout/forget-pwd-layout.component';
import { ResetPwdLayoutComponent } from './components/layout/reset-pwd-layout/reset-pwd-layout.component';
import { GuestGuard } from './_guards/guest.guard';
import { ManageAddressesComponent } from './components/dashboard/manage-addresses/manage-addresses.component';
import { AuthGuard } from './_guards/auth.guard';
import { ChatComponent } from './components/chat/chat.component';

const routes: Routes = [
 
  {
    path: 'reset-password/:string',
    component: ResetPwdLayoutComponent,
    loadChildren:()=>import('./components/reset-password/reset-password.module').then(
      (m)=> m.ResetPasswordModule
    ),
    canActivate: [GuestGuard],
  },
  
  {
    path: 'forgot-password',
    component: ForgetPwdLayoutComponent,
    loadChildren:()=>import('./components/forgot-password/forget-password.module').then(
      (m)=> m.ForgetPasswordModule
    ),
    canActivate: [GuestGuard],
  },
  {
    path: 'login',
    component: LoginLayoutComponent,
    loadChildren:()=>import('./components/login/login.module').then(
      (m)=> m.LoginModule
    ),
    canActivate: [GuestGuard],
  },
  {
    path: '',
    component: FrontLayoutComponent,
    loadChildren:()=>import('./components/home.module').then(
      (m)=> m.HomeModule
    )
  },
  {
    path : "chat",
    component : ChatComponent,  
    canActivate: [AuthGuard],
    // children: [
    //   {
    //     path: '',
    //     outlet: 'header',
    //     loadChildren: () => import('./components/header/header.module').then(m => m.HeaderModule)
    //   }
    // ]
  },

  // {
  //   path: 'manage-addresses',
  //   component: ManageAddressesComponent,
  //   canActivate: [AuthGuard],
  // },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]

})
export class AppRoutingModule { }
