import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  apiDomain				=	`${environment.domain}`;
  chatUserApi		    =	'chat-users?order_number=';
  chatHistoryApi		=	'chat-history?sender_id=';
  chatDriverHistoryApi		=	'chat-history-driver?sender_id=';
  saveChatHistoryApi		=	'saveChatHistory';
  unreadNotificationCountApi		=	'unread-notification-count';
  chatFileUploadApi 			=  'chat-file-upload';

  
  
  constructor(public router: Router,private http: HttpClient) {  
	
  }
  	chatUser(order_number,name){
		const httpOptions = {
		  headers: new HttpHeaders({			
			 "Authorization": `Bearer ${JSON.parse(localStorage.getItem('authUser'))?.token}`
		  })
		};
		return this.http.get<any>(this.apiDomain+this.chatUserApi+order_number+'&name='+name,httpOptions).pipe(map(res=>{
			return res;
		}));
	}

	uploadImage(image){
		const httpOptions = {
		  headers: new HttpHeaders({			
			 "Authorization": `Bearer ${JSON.parse(localStorage.getItem('authUser'))?.token}`
		  })
		};
		let formData = new FormData();
		formData.append('image',image)
		return this.http.post<any>(this.apiDomain+this.chatFileUploadApi,formData,httpOptions).pipe(map(res=>{
			return res;
		}));
	
	}


	
    chatHistory(sender_id,receiver_id,order_id){
		const httpOptions = {
		  headers: new HttpHeaders({			
			 "Authorization": `Bearer ${JSON.parse(localStorage.getItem('authUser'))?.token}`
		  })
		};
		return this.http.get<any>(this.apiDomain+this.chatHistoryApi+sender_id+'&receiver_id='+receiver_id+'&order_id='+order_id,httpOptions).pipe(map(res=>{
			return res;
		}));
	
	}

	chatDriverHistory(sender_id,receiver_id,order_id){
		const httpOptions = {
		  headers: new HttpHeaders({			
			 "Authorization": `Bearer ${JSON.parse(localStorage.getItem('authUser'))?.token}`
		  })
		};
		return this.http.get<any>(this.apiDomain+this.chatDriverHistoryApi+sender_id+'&receiver_id='+receiver_id+'&order_id='+order_id,httpOptions).pipe(map(res=>{
			return res;
		}));
	
	}

	

	saveChatHistory(data){
		const httpOptions = {
		  headers: new HttpHeaders({
			
			 "Authorization": `Bearer ${JSON.parse(localStorage.getItem('authUser'))?.token}`
		  })
		};
		return this.http.post<any>(`${environment.socketUrl}`+this.saveChatHistoryApi,data,httpOptions).pipe(map(res=>{
			return res;
		}));
	}
	

	unreadNotificationCount(){
		const httpOptions = {
		  headers: new HttpHeaders({			
			 "Authorization": `Bearer ${JSON.parse(localStorage.getItem('authUser'))?.token}`
		  })
		};
		return this.http.get<any>(this.apiDomain+this.unreadNotificationCountApi,httpOptions).pipe(map(res=>{
			return res;
		}));
	}
	
	

	
	
}