import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './_guards/auth.guard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {ShareModule} from '../app/shared/share.module'
import { LoaderComponent } from './components/loader/loader.component';
import { LoaderInterceptorService } from './interceptors/loader-interceptor.service';
import { LoaderService } from './services/loader.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { GuestGuard } from './_guards/guest.guard';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from 'src/environments/environment' 
import { InputMaskDirective } from './input-mask.directive';
import { WebChatService } from './chat-service.service';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { ChatComponent } from './components/chat/chat.component';

// import { ManageAddressesComponent } from './components/dashboard/manage-addresses/manage-addresses.component';

const config: SocketIoConfig = { url:`${environment.socketUrl}`, options: {} };

@NgModule({
  declarations: [
    AppComponent, 
    LoaderComponent,
    // ChatComponent,
    // InputMaskDirective
    // ManageAddressesComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule, 
    ShareModule,
    HttpClientModule,
    ModalModule.forRoot(),
    SocketIoModule.forRoot(config),  
    // NgxStripeModule.forRoot(`${environment.stripPublistKey}`),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true },
    AuthGuard,
    GuestGuard,
    LoaderService,
    WebChatService
  ],
  // exports: [
  //   InputMaskDirective
  // ],
  bootstrap: [AppComponent]
})
export class AppModule { }
