<!-- delete confimation -->
<ng-template #remove_address>
    <div class="modal-body cofirmModal">
      <div class="infoIcon_wall">
        <i class="ion-information"></i>
      </div>
      <h4 class="modal-title">Are you sure you want to remove this?</h4>
    </div>
    <div class="modal-footer formSection">
      <button class="btn btn-secondary" (click)="removeAddress()">Remove</button>
      <button class="btn btn-danger" (click)="modalRef.hide()">Cancel</button>
    </div>
  </ng-template> 
<!-- Edit Address Modal --> 
<div class="modal fade" id="addressCenter" tabindex="-1" role="dialog" aria-labelledby="addressCenter"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="addressLongTitle">{{type}} your address</h5>
                    <button type="button" class="close" (click)="close()" #closeAddressModal data-dismiss="modal" aria-label="Close">
                        <i class="fal fa-times"></i>
                    </button>
                </div>
                <!-- for map -->
                <div class="mapParentPadding">
                    <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
                      <agm-marker
                        [latitude]="latitude"
                        [longitude]="longitude"
                        [markerDraggable]="true"
                        (dragEnd)="markerDragEnd($event)"
                      ></agm-marker>
                    </agm-map>
                    <a
                      href="javascript:(void);"
                      class="current-location"
                      (click)="setCurrentLocation()"
                      ><i
                        class="fa fa-circle"
                        [ngClass]="{ locationColor: locationColor === true }"
                      ></i
                    ></a>
                  </div>                 
                <form [formGroup]="manageAddressesForm" (ngSubmit)="onSubmit()">
                    <div class="modal-body">
                        <div class="checkout_form">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Full name (First and Last name)</label>
                                        <div class="theme-input">
                                            <input type="text" formControlName="name" class="form-control" value="Vishal">
                                        </div>
                                        <div *ngIf="submitted && f.name.errors" class="text-danger help-inline">
                                            <div *ngIf="f.name.errors.required">First Name is required.</div>
                                        </div>  
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Mobile number</label>
                                        <div class="theme-input">
                                            <input type="text" prefix="+27"  mask="000000000" [showMaskTyped]="false"  formControlName="number" class="form-control" (keypress)="numberOnly($event)">
                                        </div>
                                        <div *ngIf="submitted && f.number.errors" class="text-danger help-inline">
                                            <div *ngIf="f.number.errors.required">Phone number is required.</div>
                                            <div *ngIf="f.number.errors.minlength || f.number.errors.maxlength">
                                                Please enter valid phone number.
                                            </div>
                                        </div>
                                        <div *ngIf="submitted && is_phone_invalid && !f.number.errors" class="text-danger help-inline">
                                            <div *ngIf="is_phone_invalid">Please enter valid phone number.</div>
                                        </div>
                                        
                                    </div> 
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Address Type</label>
                                        <div class="theme-input">
                                            <select class="custom-select form-control" formControlName="address_type" name="">
                                                <option value="" hidden selected>Select Address Type</option>                                                
                                                <option value="home">Home</option>
                                                <option value="work">Office</option>
                                                <option value="complex">Complex</option>
                                                <option value="apartment">Apartment</option>
                                            </select>
                                        </div>
                                        <div *ngIf="submitted && f.address_type.errors" class="text-danger help-inline">
                                            <div *ngIf="f.address_type.errors.required">Address Type is required.</div>
                                        </div>  
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Address </label>
                                        <div class="theme-input">
                                            <input #searchInput
                                            type="text"
                                            placeholder="Search Nearest Location"
                                            autocorrect="off"
                                            autocapitalize="off"
                                            (keydown.enter)="$event.preventDefault()"
                                            (keypress)="keypress()"
                                            spellcheck="off" >
                                        </div>
                                        <div *ngIf="submitted && (address == '' && error)" class="text-danger help-inline">
                                            <div *ngIf="error && address == ''">Address  is required.</div>
                                        </div> 
                                    </div>
                                </div>
                                <!-- <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Surburb</label>
                                        <div class="theme-input">
                                            <input type="text" formControlName="Surburb" class="form-control" value="test">
                                        </div>
                                        <div *ngIf="submitted && f.Surburb.errors" class="text-danger help-inline">
                                            <div *ngIf="f.Surburb.errors.required">Surburb is required.</div>
                                        </div> 
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>City</label>
                                        <div class="theme-input">
                                            <input type="text" formControlName="City" class="form-control" value="Western Cape">
                                        </div>
                                        <div *ngIf="submitted && f.City.errors" class="text-danger help-inline">
                                            <div *ngIf="f.City.errors.required">City is required.</div>
                                        </div> 
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Province</label>
                                        <div class="theme-input">
                                            <input type="text" formControlName="Province" class="form-control" value="Western Cape">
                                        </div>
                                        <div *ngIf="submitted && f.Province.errors" class="text-danger help-inline">
                                            <div *ngIf="f.Province.errors.required">Province is required.</div>
                                        </div> 
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Postal Code</label>
                                        <div class="theme-input">
                                            <input type="text" formControlName="Postal_Code" (keypress)="numberOnly($event)" class="form-control" value="7304">
                                        </div>
                                        <div *ngIf="submitted && f.Postal_Code.errors" class="text-danger help-inline">
                                            <div *ngIf="f.Postal_Code.errors.required">Postal Code is required.</div>
                                        </div> 
                                    </div>
                                </div> -->
                            </div>
        
                        </div>
                    </div>
                    <div class="modal-footer">
                        <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal" aria-label="Close" (click)="close()">Close</button> -->
                        <button type="submit" class="btn btn-primary">Save</button>
                    </div>
                </form>
        </div>
    </div>
</div>

<!-- Add Address Modal -->

<!-- Manage Address -->

<div class="manage-address-wrap gray-pages" *ngIf="currentUrl == '/manage-addresses'">
    <div class="container px-0 px-md-2">
        <nav aria-label="breadcrumb" class="bread-custom">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0);" [routerLink]="['']">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Your Addresses</li>
            </ol>
        </nav>
        <div class="products_head">
            <h3>Your Addresses</h3>

            <!-- <a href="javascript:void(0);" class="pageBack-btn">Back</a> -->
        </div>

        <div class="manage-address-inner">

            <ul class="nav nav-tabs" id="myTab" role="tablist">

                <li class="nav-item">
                    <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home"
                        aria-selected="false" (click)="typeOfAddress('home')">Home</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" id="office-tab" data-toggle="tab" href="#office" role="tab"
                        aria-controls="office" aria-selected="true" (click)="typeOfAddress('work')">Office</a>
                </li>
            </ul>

            <div class="tab-content" id="myTabContent">

                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <div class="row">

                        <div class="col-sm-6 col-md-6 col-lg-4">
                            <a href="javascript:void(0);" class="add-address-card" data-toggle="modal" data-target="#addressCenter" (click)="openModal('add')">                                
                                <i class="far fa-plus"></i>
                                Add address
                            </a>
                        </div>

                        <div class="col-sm-6 col-md-6 col-lg-4" *ngFor="let address of homeAddress; let i=index">
                            <div class="aly-address default-aly-address">
                                <h5 class="default_address" *ngIf="address.is_default == 1">
                                    Default:
                                </h5>

                                <div class="aly-address-content">
                                    <h4>{{ address.name }}</h4>
                                    <span>{{address.house_number}} </span>
                                    <span>{{address.address}}</span>
                                    <span>{{address.landmark}}</span>
                                    <!-- <span>South Africa</span> -->
                                    <span>Phone number: {{ address.phone_number }}</span>
                                </div>
                                <div class="aly-address-action">
                                    <a href="javascript:void(0);" data-toggle="modal" data-target="#addressCenter" (click)="openModal('edit',i,address.id,'home')">                                        
                                        Edit
                                    </a>
                                    &nbsp; | &nbsp;
                                    <a href="javascript:void(0);" (click)="openDeleteModal(remove_address,address.id,i,'home')">
                                        Remove
                                    </a>
                                    <!-- &nbsp; | &nbsp;
                                    <a href="javascript:void(0);" *ngIf="address.is_default == 0">
                                        Set as Default
                                    </a> -->
                                </div>
                            </div>
                        </div>

                        <!-- <div class="col-sm-6 col-md-6 col-lg-4">
                            <div class="aly-address">

                                <div class="aly-address-content">
                                    <h4>Vishal Verma</h4>
                                    <span>1502 </span>
                                    <span>St. John Street</span>
                                    <span>Western Cape</span>
                                    <span>South Africa</span>
                                    <span>Phone number: 085 903 4375</span>
                                </div>
                                <div class="aly-address-action">
                                    <a href="javascript:void(0);" data-toggle="modal" data-target="#addressCenter">
                                        Edit
                                    </a>
                                    &nbsp; | &nbsp;
                                    <a href="javascript:void(0);">
                                        Remove
                                    </a>
                                    &nbsp; | &nbsp;
                                    <a href="javascript:void(0);">
                                        Set as Default
                                    </a>
                                </div>
                            </div>
                        </div> -->

                        <!-- <div class="col-sm-6 col-md-6 col-lg-4">
                            <div class="aly-address">

                                <div class="aly-address-content">
                                    <h4>Vishal Verma</h4>
                                    <span>1502 </span>
                                    <span>St. John Street</span>
                                    <span>Western Cape</span>
                                    <span>South Africa</span>
                                    <span>Phone number: 085 903 4375</span>
                                </div>
                                <div class="aly-address-action">
                                    <a href="javascript:void(0);" data-toggle="modal" data-target="#addressCenter">
                                        Edit
                                    </a>
                                    &nbsp; | &nbsp;
                                    <a href="javascript:void(0);">
                                        Remove
                                    </a>
                                    &nbsp; | &nbsp;
                                    <a href="javascript:void(0);">
                                        Set as Default
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6 col-md-6 col-lg-4">
                            <div class="aly-address">
                                <div class="aly-address-content">
                                    <h4>Vishal Verma</h4>
                                    <span>1502 </span>
                                    <span>St. John Street</span>
                                    <span>Western Cape</span>
                                    <span>South Africa</span>
                                    <span>Phone number: 085 903 4375</span>
                                </div>
                                <div class="aly-address-action">
                                    <a href="javascript:void(0);" data-toggle="modal" data-target="#addressCenter">
                                        Edit
                                    </a>
                                    &nbsp; | &nbsp;
                                    <a href="javascript:void(0);">
                                        Remove
                                    </a>
                                    &nbsp; | &nbsp;
                                    <a href="javascript:void(0);">
                                        Set as Default
                                    </a>
                                </div>
                            </div>
                        </div> -->

                    </div>
                </div>


                <div class="tab-pane fade" id="office" role="tabpanel" aria-labelledby="office-tab">
                    <div class="row">
                        <div class="col-sm-6 col-md-6 col-lg-4">
                            <a href="javascript:void(0);" class="add-address-card" data-toggle="modal" data-target="#addressCenter" (click)="openModal('add')" >                                
                                <i class="far fa-plus"></i>
                                Add address
                            </a>
                        </div>

                        <div class="col-sm-6 col-md-6 col-lg-4" *ngFor="let address of officeAddress; let i=index">
                            <div class="aly-address default-aly-address">
                                <h5 class="default_address" *ngIf="address.is_default == 1">
                                    Default:
                                </h5>

                                <div class="aly-address-content">
                                    <h4>{{ address.name }}</h4>
                                    <span>{{address.house_number}} </span>
                                    <span>{{address.address}}</span>
                                    <span>{{address.landmark}}</span>
                                    <!-- <span>South Africa</span> -->
                                    <span>Phone number: {{ address.phone_number }}</span>
                                </div>
                                <div class="aly-address-action">
                                    <a href="javascript:void(0);" data-toggle="modal" data-target="#addressCenter" (click)="openModal('edit',i,address.id,'office')">                                        
                                        Edit 
                                    </a>
                                    &nbsp; | &nbsp;
                                    <a href="javascript:void(0);" (click)="openDeleteModal(remove_address,address.id,i,'office')">
                                        Remove
                                    </a>
                                    <!-- &nbsp; | &nbsp;
                                    <a href="javascript:void(0);" *ngIf="address.is_default == 0">
                                        Set as Default
                                    </a> -->
                                </div>
                            </div>
                        </div>

                        <!-- <div class="col-sm-6 col-md-6 col-lg-4">
                            <div class="aly-address">

                                <div class="aly-address-content">
                                    <h4>Vishal Verma</h4>
                                    <span>1502 </span>
                                    <span>St. John Street</span>
                                    <span>Western Cape</span>
                                    <span>South Africa</span>
                                    <span>Phone number: 085 903 4375</span>
                                </div>
                                <div class="aly-address-action">
                                    <a href="javascript:void(0);" data-toggle="modal" data-target="#addressCenter">
                                        Edit
                                    </a>
                                    &nbsp; | &nbsp;
                                    <a href="javascript:void(0);">
                                        Remove
                                    </a>
                                    &nbsp; | &nbsp;
                                    <a href="javascript:void(0);">
                                        Set as Default
                                    </a>
                                </div>
                            </div>
                        </div> -->

                    </div>
                </div>


            </div>

        </div>


    </div>
</div>

<div class="manage-address-wrap gray-pages" *ngIf="currentUrl == '/checkout'">
    <form [formGroup]="selectAddressForm">
    <div class="container px-0">
        <!-- <nav aria-label="breadcrumb" class="bread-custom">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0);" [routerLink]="['']">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Your Addresses</li>
            </ol>
        </nav> -->
        <!-- <div class="products_head">
            <h3>Your Addresses</h3>

            <a href="javascript:void(0);" class="pageBack-btn">Back</a>
        </div> -->

        <div class="manage-address-inner">

            <!-- <ul class="nav nav-tabs" id="myTab" role="tablist">

                <li class="nav-item">
                    <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home"
                        aria-selected="false">Home</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" id="office-tab" data-toggle="tab" href="#office" role="tab"
                        aria-controls="office" aria-selected="true">Office</a>
                </li>
            </ul> -->

            <div class="tab-content" id="myTabContent">

                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <div class="row">

                        <div class="col-sm-6 col-md-6 col-lg-4">
                            <a href="javascript:void(0);" class="add-address-card" data-toggle="modal" data-target="#addressCenter" (click)="openModal('add')">                                
                                <i class="far fa-plus"></i>
                                Add address
                            </a>
                        </div>

                        <div class="col-sm-6 col-md-6 col-lg-4" *ngFor="let address of addresses; let i=index">
                            
                            
                            <div class="aly-address default-aly-address newAlyAdderess">

                                <div class="addressRadiocheck">
                                    <input type="radio" id="labelRadcheck_{{i}}" hidden name="address_radio" formControlName="address_radio" value="{{i}}" (change)="selectedAddress(addresses[i])">
                                    <label for="labelRadcheck_{{i}}"></label>
                                </div>

                                <h5 class="default_address" *ngIf="address.is_default == 1">
                                    Default:
                                </h5>

                                <div class="aly-address-content">
                                    <h4>{{ address.name }}</h4>
                                    <h4>{{ address.type }}</h4>
                                    <span>{{address.house_number}} </span>
                                    <span>{{address.address}}</span>
                                    <span>{{address.landmark}}</span>
                                    <!-- <span>South Africa</span> -->
                                    <span>Phone number: {{ address.phone_number }}</span>
                                </div>
                                <!-- <div class="aly-address-action">
                                    <a href="javascript:void(0);" data-toggle="modal" data-target="#addressCenter" (click)="openModal('edit',i,address.id,'home')">                                        
                                        Edit
                                    </a>
                                    &nbsp; | &nbsp;
                                    <a href="javascript:void(0);" (click)="openDeleteModal(remove_address,address.id,i,'home')">
                                        Remove
                                    </a>
                                    &nbsp; | &nbsp;
                                    <a href="javascript:void(0);" *ngIf="address.is_default == 0">
                                        Set as Default
                                    </a>
                                </div> -->
                            </div>
                        </div>

                        <!-- <div class="col-sm-6 col-md-6 col-lg-4" *ngFor="let address of officeAddress; let i=index">                            
                            <input type="radio" name="radio" (change)="selectedAddress(officeAddress[i])" >
                            <div class="aly-address default-aly-address">
                                <h5 class="default_address" *ngIf="address.is_default == 1">
                                    Default:
                                </h5>

                                <div class="aly-address-content">
                                    <h4>{{ address.name }}</h4>
                                    <h4>{{ address.type }}</h4>
                                    <span>{{address.house_number}} </span>
                                    <span>{{address.address}}</span>
                                    <span>{{address.landmark}}</span>
                                    <span>Phone number: {{ address.phone_number }}</span>
                                </div>
                            </div>
                        </div> -->

                        <!-- <div class="col-sm-6 col-md-6 col-lg-4">
                            <div class="aly-address">

                                <div class="aly-address-content">
                                    <h4>Vishal Verma</h4>
                                    <span>1502 </span>
                                    <span>St. John Street</span>
                                    <span>Western Cape</span>
                                    <span>South Africa</span>
                                    <span>Phone number: 085 903 4375</span>
                                </div>
                                <div class="aly-address-action">
                                    <a href="javascript:void(0);" data-toggle="modal" data-target="#addressCenter">
                                        Edit
                                    </a>
                                    &nbsp; | &nbsp;
                                    <a href="javascript:void(0);">
                                        Remove
                                    </a>
                                    &nbsp; | &nbsp;
                                    <a href="javascript:void(0);">
                                        Set as Default
                                    </a>
                                </div>
                            </div>
                        </div> -->

                        <!-- <div class="col-sm-6 col-md-6 col-lg-4">
                            <div class="aly-address">

                                <div class="aly-address-content">
                                    <h4>Vishal Verma</h4>
                                    <span>1502 </span>
                                    <span>St. John Street</span>
                                    <span>Western Cape</span>
                                    <span>South Africa</span>
                                    <span>Phone number: 085 903 4375</span>
                                </div>
                                <div class="aly-address-action">
                                    <a href="javascript:void(0);" data-toggle="modal" data-target="#addressCenter">
                                        Edit
                                    </a>
                                    &nbsp; | &nbsp;
                                    <a href="javascript:void(0);">
                                        Remove
                                    </a>
                                    &nbsp; | &nbsp;
                                    <a href="javascript:void(0);">
                                        Set as Default
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6 col-md-6 col-lg-4">
                            <div class="aly-address">
                                <div class="aly-address-content">
                                    <h4>Vishal Verma</h4>
                                    <span>1502 </span>
                                    <span>St. John Street</span>
                                    <span>Western Cape</span>
                                    <span>South Africa</span>
                                    <span>Phone number: 085 903 4375</span>
                                </div>
                                <div class="aly-address-action">
                                    <a href="javascript:void(0);" data-toggle="modal" data-target="#addressCenter">
                                        Edit
                                    </a>
                                    &nbsp; | &nbsp;
                                    <a href="javascript:void(0);">
                                        Remove
                                    </a>
                                    &nbsp; | &nbsp;
                                    <a href="javascript:void(0);">
                                        Set as Default
                                    </a>
                                </div>
                            </div>
                        </div> -->

                    </div>
                </div>                


            </div>

        </div>


    </div>
</form>
</div>



