
import { Injectable } from '@angular/core'
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import {map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import {environment} from '../../environments/environment';
import * as globalConstant from '../shared/global'; 

@Injectable({
    providedIn: 'root'
})

export class DashboardService {

	
	apiDomain					=	`${environment.domain}`;
	updateProfileImageApi       =   globalConstant.UPDATE_PROFILE_IMAGE_API;
	editProfileApi        		=   globalConstant.EDIT_PROFILE_API;
	addAddressApi				=   globalConstant.ADD_ADDRESS_API;
	editAddressApi				=	globalConstant.EDIT_ADDRESS_API
	addressesApi				=	globalConstant.ADDRESSES_API;
	deleteAddressApi			=	globalConstant.DELETE_ADDRESS_API;
	getOrderHistoryApi			=	globalConstant.GET_ORDER_HISTORY_API;
	getTrackOrderDetailApi		=	globalConstant.GET_TRACK_ORDER_DETAIL_API;
	reviewOrderItemApi			=	globalConstant.REVIEW_ORDER_ITEM_API;
	getTransactionsApi			=	globalConstant.GET_TRANSACTIONS_API;
	getWalletHistoriesApi		=	globalConstant.GET_WALLET_HISTORY_API;
	getWalletApi				=	globalConstant.GET_WALLET_API;
	addBalanceInWalletApi		=	globalConstant.ADD_BALANCE_IN_WALLET_API;
	addBalanceInWalletPaymentApi		=	globalConstant.ADD_BALANCE_IN_WALLET_PAYMENT_API;
	accept_reject_order_item_api=	globalConstant.ACCEPT_REJECT_ORDER_ITEM_API;
	
    constructor(
		public router: Router,
		private http: HttpClient
	){}  

  


    updateProfileImage(data){
        const httpOptions = {
			headers: new HttpHeaders({
		
				"Authorization": `Bearer ${JSON.parse(localStorage.getItem('authUser')).token}`
			})
		};


		return this.http.post<any>(this.apiDomain+this.updateProfileImageApi,data,httpOptions).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
        }));
	} 

	editProfile(data){
        const httpOptions = {
			headers: new HttpHeaders({
		
				"Authorization": `Bearer ${JSON.parse(localStorage.getItem('authUser')).token}`
			})
		};


		return this.http.post<any>(this.apiDomain+this.editProfileApi,data,httpOptions).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
        }));
	}

	addAddress(data){
        const httpOptions = {
			headers: new HttpHeaders({
		
				"Authorization": `Bearer ${JSON.parse(localStorage.getItem('authUser')).token}`
			})
		};


		return this.http.post<any>(this.apiDomain+this.addAddressApi,data,httpOptions).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
        }));
	}

	editAddress(data){
        const httpOptions = {
			headers: new HttpHeaders({
		
				"Authorization": `Bearer ${JSON.parse(localStorage.getItem('authUser')).token}`
			})
		};


		return this.http.post<any>(this.apiDomain+this.editAddressApi,data,httpOptions).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
        }));
	}

	addresses(){
        const httpOptions = {
			headers: new HttpHeaders({
		
				"Authorization": `Bearer ${JSON.parse(localStorage.getItem('authUser')).token}`
			})
		};


		return this.http.get<any>(this.apiDomain+this.addressesApi,httpOptions).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
        }));
	}

	deleteAddress(id){
        const httpOptions = {
			headers: new HttpHeaders({
		
				"Authorization": `Bearer ${JSON.parse(localStorage.getItem('authUser')).token}`
			})
		};


		return this.http.delete<any>(this.apiDomain+this.deleteAddressApi+'?id=' + id,httpOptions).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
        }));
	}

	getOrderHistory(page){
        const httpOptions = {
			headers: new HttpHeaders({
		
				"Authorization": `Bearer ${JSON.parse(localStorage.getItem('authUser')).token}`
			})
		};


		return this.http.get<any>(this.apiDomain+this.getOrderHistoryApi+'?page='+page,httpOptions).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
        }));
	}

	getTrackOrderDetail(orderId){
        const httpOptions = {
			headers: new HttpHeaders({
		
				"Authorization": `Bearer ${JSON.parse(localStorage.getItem('authUser')).token}`
			})
		};


		return this.http.get<any>(this.apiDomain+this.getTrackOrderDetailApi+orderId,httpOptions).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
        }));
	}

	writeReviewOrderItem(orderItemId,productId,productVarientId,rating,review,driver){
		const httpOptions = {
			headers: new HttpHeaders({
		
				"Authorization": `Bearer ${JSON.parse(localStorage.getItem('authUser')).token}`
			})
		};

		// +'&driver_rating='+driver
		return this.http.get<any>(this.apiDomain+this.reviewOrderItemApi+orderItemId+'&product_id='+productId+'&product_variant_id='+productVarientId+'&rating='+rating+'&review='+review,httpOptions).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
        }));
	}
	
	getTransactions(page, date_from , date_to){
		const httpOptions = {
			headers: new HttpHeaders({
		
				"Authorization": `Bearer ${JSON.parse(localStorage.getItem('authUser')).token}`
			})
		};


		return this.http.get<any>(this.apiDomain+this.getTransactionsApi+page+'&date_from='+date_from+'&date_to='+date_to,httpOptions).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
        }));
	}

	getWalletHistories(page, date_from , date_to){
		const httpOptions = {
			headers: new HttpHeaders({
		
				"Authorization": `Bearer ${JSON.parse(localStorage.getItem('authUser')).token}`
			})
		};


		return this.http.get<any>(this.apiDomain+this.getWalletHistoriesApi+page+'&date_from='+date_from+'&date_to='+date_to,httpOptions).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
        }));
	}

	getWallet(){
		const httpOptions = {
			headers: new HttpHeaders({
		
				"Authorization": `Bearer ${JSON.parse(localStorage.getItem('authUser')).token}`
			})
		};


		return this.http.get<any>(this.apiDomain+this.getWalletApi,httpOptions).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
        }));
	}

	addBalanceInWallet(amount){
		const httpOptions = {
			headers: new HttpHeaders({
		
				"Authorization": `Bearer ${JSON.parse(localStorage.getItem('authUser')).token}`
			})
		};


		return this.http.get<any>(this.apiDomain+this.addBalanceInWalletApi+amount,httpOptions).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
        }));
	}

	addBalanceInWalletPayment(data){
		const httpOptions = {
			headers: new HttpHeaders({
		
				"Authorization": `Bearer ${JSON.parse(localStorage.getItem('authUser')).token}`
			})
		};


		return this.http.post<any>(this.apiDomain+this.addBalanceInWalletPaymentApi,data,httpOptions).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
        }));
	}

	accept_reject_order_item(string){
		const httpOptions = {
			headers: new HttpHeaders({
		
				"Authorization": `Bearer ${JSON.parse(localStorage.getItem('authUser')).token}`
			})
		};
		return this.http.get<any>(this.apiDomain+this.accept_reject_order_item_api+string,httpOptions).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
        }));
	}
	
}