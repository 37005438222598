// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = { 
  production: false,
  // domain : 'https://grocerypanel.stage02.obdemo.com/api/customer/v1/',
  domain : 'https://grocerypanel.stage02.obdemo.com/admin/api/customer/v1/',
  // domain : 'http://groceryadmin.dev21.obtech.inet/api/customer/v1/',
  domain_shopper:'https://grocerypanel.stage02.obdemo.com/admin/api/shopper/v1/',
  mapApiKey: 'AIzaSyA6s497mKnN7hP7fzCRIOOAJ_wvpG-2RLM',
  // mapApiKey: 'AIzaSyBriZun3VSgjQUTZJJDhZhsBwkh7iqkaXA', 
  // google_client_key : '540981361860-kr3t3hs5cuq4avr9kqr7e0aarjbq4opl.apps.googleusercontent.com',
  google_client_key : '817933320801-2qrr0b7refolijmmp7tcnfsluccni94d.apps.googleusercontent.com',
  facebook_app_id : '850091668934737',
  stripPublistKey : 'pk_test_KWUbBUfLipJXZ4VE2PqBYZ4K',
  socketUrl : 'https://groceryns.stage02.obdemo.com/',
};
 
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
