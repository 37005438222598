import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute ,Params} from '@angular/router';
import { MessageService } from '../../message.service';
import { CmsService } from 'src/app/services/cms.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  category_id : any = ''
  sub_category_id : any = ''
  sortBy : any = ''
  price : any = ''
  brand_list_ids : any  = ''
  store_list_ids : any = ''
  kayword :any = ''
  life_style_list_ids : any = ''
  footerDetail : any;
  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private messageService :MessageService,
    private cmsService :CmsService) { 
      this.activatedRoute.queryParams.subscribe(params => {
        this.category_id = params['category'] ?atob(params['category']) : '';
        this.sub_category_id = params['sub_category'] ?atob(params['sub_category']) : '';
        this.sortBy = params['sort_by'] ?atob(params['sort_by']) : '';
        this.price = params['price'] ?atob(params['price']) : '';
        this.brand_list_ids = params['brand'] ?atob(params['brand']) : '';
        this.store_list_ids = params['store'] ?atob(params['store']) : '';
        this.kayword = params['name'] ?atob(params['name']) : '';
        this.life_style_list_ids = params['life_styles'] ?atob(params['life_styles']) : '';
      });

      this.messageService.getCategoryMessage().subscribe(message => {
		
        if(message)
        {
         
          this.category_id =  message.category
          this.sub_category_id = message.sub_category
          this.sortBy = message.sort_by
          this.price = message.price
          this.brand_list_ids = message.brand
          this.store_list_ids = message.store,
          this.life_style_list_ids = message.life_styles
        }
      });
    }

  ngOnInit(): void {
    this.cmsService.footer().subscribe(res => {
      if (res.status == "success") {
          
          this.footerDetail =  res;
      }
  }); 

  }

  productList()
  {
    let url = '/products?category='+btoa(this.category_id)+'&sub_category='+btoa(this.sub_category_id)+'&sort_by='+btoa(this.sortBy)+'&price='+btoa(this.price)+'&brand='+btoa(this.brand_list_ids)+'&store='+btoa(this.store_list_ids)+'&name='+btoa(this.kayword)+'&life_styles='+btoa(this.life_style_list_ids)
    this.router.navigateByUrl(url)
  }
}
 