import { Injectable } from '@angular/core'
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import {map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import {environment} from '../../environments/environment';
import * as globalConstant from '../shared/global'; 

@Injectable({
    providedIn: 'root' 
})

export class CartService {

	
	apiDomain				        =	`${environment.domain}`;
	addTpCartApi                	=   globalConstant.ADD_TO_CART_API;
	deleteAllCartApi				=   globalConstant.DELETE_ALL_CART_API;
	cartApi                			=   globalConstant.CARTS_API;
	cartCountApi                	=   globalConstant.CART_COUNT_API;
	updateCartApi                	=   globalConstant.UPDATE_CART_API;
	removeFromCartApi               =   globalConstant.REMOVE_FROM_CART_API;
	applyCouponCodeApi				=	globalConstant.APPLY_COUPON_CODE;
	cartListApi						=	globalConstant.CART_LIST_API;
	updateCartInstructionApi		=	globalConstant.UPDATE_CART_INSTRUCTION_API;

	
	
	
    constructor(
		public router: Router,
		private http: HttpClient
	){} 
 
    add_to_cart(product_id,product_variant_id,unit_id,quantity){
		const httpOptions = {
			headers: new HttpHeaders({
		
				"Authorization": `Bearer ${JSON.parse(localStorage.getItem('authUser')).token}`
			})
		};
		return this.http.get<any>(this.apiDomain+this.addTpCartApi+product_id+'&product_variant_id='+product_variant_id+'&unit_id='+unit_id+'&quantity='+quantity,httpOptions).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
          }));
	}

	 
    deleteAllCart(){
		const httpOptions = {
			headers: new HttpHeaders({		
				"Authorization": `Bearer ${JSON.parse(localStorage.getItem('authUser')).token}`
			})
		};
		return this.http.get<any>(this.apiDomain+this.deleteAllCartApi,httpOptions).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
          }));
	}


	

	cart(latitude,longitude){
		const httpOptions = {
			headers: new HttpHeaders({
		
				"Authorization": `Bearer ${JSON.parse(localStorage.getItem('authUser')).token}`
			})
		};
		return this.http.get<any>(this.apiDomain+this.cartApi+latitude+'&longitude='+longitude,httpOptions).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
          }));
	}

	cartList(latitude,longitude){
		const httpOptions = {
			headers: new HttpHeaders({
		
				"Authorization": `Bearer ${JSON.parse(localStorage.getItem('authUser')).token}`
			})
		};
		return this.http.get<any>(this.apiDomain+this.cartListApi+'?latitude='+latitude+'&longitude='+longitude,httpOptions).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
          }));
	}

	
	cartCount(){
		const httpOptions = {
			headers: new HttpHeaders({
		
				"Authorization": `Bearer ${JSON.parse(localStorage.getItem('authUser')).token}`
			})
		};
		return this.http.get<any>(this.apiDomain+this.cartCountApi,httpOptions).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
          }));
	}
	
	
	update_cart(product_id,product_variant_id,unit_id,quantity,cart_id){
		const httpOptions = {
			headers: new HttpHeaders({
		
				"Authorization": `Bearer ${JSON.parse(localStorage.getItem('authUser')).token}`
			})
		};
		return this.http.get<any>(this.apiDomain+this.updateCartApi+product_id+'&product_variant_id='+product_variant_id+'&unit_id='+unit_id+'&quantity='+quantity+'&cart_id='+cart_id,httpOptions).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
          }));
	}

	removeFromCart(cart_id){
		const httpOptions = {
			headers: new HttpHeaders({
		
				"Authorization": `Bearer ${JSON.parse(localStorage.getItem('authUser')).token}`
			})
		};

		return this.http.get<any>(this.apiDomain+this.removeFromCartApi+cart_id, httpOptions).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
          }));
	}

	applyCouponCode(amount, code){
		const httpOptions = {
			headers: new HttpHeaders({
		
				"Authorization": `Bearer ${JSON.parse(localStorage.getItem('authUser')).token}`
			})
		};

		return this.http.get<any>(this.apiDomain+this.applyCouponCodeApi+'?amount='+amount+'&coupon_code='+code, httpOptions).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
          }));
	}

	updateCartInstruction(cartId,instruction){
			const httpOptions = {
			headers: new HttpHeaders({
		
				"Authorization": `Bearer ${JSON.parse(localStorage.getItem('authUser')).token}`
			})
		};

		return this.http.get<any>(this.apiDomain+this.updateCartInstructionApi+'?cart_id='+cartId+'&instruction='+instruction, httpOptions).pipe(map(res=>{
			return res;
        }),
        catchError(err => {
            return err.error;
          }));
	}


	


}
