
import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';
import { isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
@Injectable()
export class WebChatService {

  constructor(private socket: Socket,
    @Inject(PLATFORM_ID) private platformId) { }

  public sendMessage(message) {
    this.socket.emit('loginChatRoom', message);
  }

  public saveMessage(message) {
    this.socket.emit('saveChatHistory', message);
  }

  public sendNotificationMessage(message) {
    
    this.socket.emit('loginNotifcationUpdateRoom', message);
  }

  public sendOrderNotificationMessage(message) {
    if (isPlatformBrowser(this.platformId)) {
      this.socket.emit("loginChatRoomOnWebsite", message);
    }
  }

  public sendReadMessage(message) {
    
    this.socket.emit('readmsg', message);
  }

  public getUserOnline = () => {
    return Observable.create((observer) => {
            this.socket.on('is_user_online', (message) => {
                observer.next(message);
            });
    });
  }

  public getMessages = () => {
    return Observable.create((observer) => {
            this.socket.on('receive_message', (message) => {
                observer.next(message);
            });
    });
  }

  public getNotifications = () => {
    return Observable.create((observer) => {
            this.socket.on('unreadMessageCount', (message) => { 
                observer.next(message);
                //this.messageService.sendNotificationCountMessage(message)
            });
    });
  } 

  public getOrderNotifications = () => {
    return Observable.create((observer) => {
            this.socket.on('unreadNotificationOnWebsite', (message) => { 
                observer.next(message);
                //this.messageService.sendNotificationCountMessage(message)
            });
    });
  } 

   initSocket(__bool ,message){   

     const $this = this
    if(__bool == true){   
        this.socket.connect()          
           
        this.socket.on('connect', function(){
          // console.log('connected')
        $this.sendMessage(message)  
      });                                 
        this.socket.on('disconnect', function (){
          // console.log('disconnected')
        });
    }else{
      this.socket.disconnect();
  
    }
  }
  
  initReadMessageSocket(__bool ,message)
  {
    
    const $this = this
   if(__bool == true){   
       this.socket.connect()          
          
       this.socket.on('connect', function(){
        //  console.log('connected')
       $this.sendReadMessage(message)  
     });                                 
       this.socket.on('disconnect', function (){
        //  console.log('disconnected')
        });
   }else{
     this.socket.disconnect();
 
   }
  }

  initNotificationSocket(__bool, message) {
    if (isPlatformBrowser(this.platformId)) {
      const $this = this;
      if (__bool == true) {
        this.socket.connect();

        this.socket.on("connect", function () {
          // console.log('connected')
          $this.sendOrderNotificationMessage(message);
        });
        this.socket.on("disconnect", function () {
          // console.log('disconnected')
        });
      } else {
        this.socket.disconnect();
      }
    }
  }
  
}