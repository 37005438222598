<!-- template -->
<ng-template #remove_from_cart>
  <div class="modal-body cofirmModal">
    <div class="infoIcon_wall">
      <i class="ion-information"></i>
    </div>
    <h4 class="modal-title">Are you sure you want to remove this item?</h4>
  </div>
  <div class="modal-footer formSection">
    <button class="btn btn-secondary" (click)="removeFromCart()">Remove</button>
    <button class="btn btn-danger" (click)="modalRef.hide()">Cancel</button>
  </div>
</ng-template> 
<!--  -->
<div class="overlay" [ngClass]="menuShow ? 'show' : ''" (click)="menuToggle()"
  [ngStyle]="{'display': card_popup_opean=== 'open' || menuShow == true  ? 'block' : 'none' }"></div>
<div class="overlayOne" [ngClass]="cateShow ? 'show' : ''" (click)="catToggle()"
  [ngStyle]="{'display': cateShow == true ? 'block' : 'none' }"></div>

<header class="g-header" id="g-header">
  <div class="nw-top-header">
    <p class="mb-0" *ngIf="header_marketing_text">{{header_marketing_text}}</p>

    <ul class="list-unstyled mb-0">
      <li>
        <a href="javascript:void(0)" [routerLink]="['/']">Home</a>
      </li>
      <li>
        <a href="javascript:void(0)" [routerLink]="['/about-us']">About Us</a>
      </li>
      <li>
        <a href="javascript:void(0)" [routerLink]="['/faq']">FAQ's</a>
      </li>
      <li>
        <a href="javascript:void(0)" [routerLink]="['/contact-us']">Help</a>
      </li>
    </ul>
  </div>
  <div class="g-top-header">
    <div class="g-logo d-flex align-items-center">
      <div class="e-menu-baar d-lg-none d-block" type="" [ngClass]="menuShow ? 'menu-opened' : ''"
        (click)="menuToggle()">
        <i class="fal fa-bars"></i>
      </div>
      <a href="javascript:void(0);" [routerLink]="['']">
        <img src="assets/img/logo.png" alt="Grozap" class="desktop-logo">

      </a>
    </div>
    <div class="g-top-search">
      <div class="g-location" >
        <!-- <div class="g-location-icon">
          <img src="assets/img/location.png" alt="Location">
        </div> -->
        <div class="g-select-location" *ngIf="current_address">

          <a href="javascript:void(0);" class="text-truncate" (click)="changeLocation()">
            <img src="assets/img/location-icon.png" alt="">
            <span >Delivery to</span> Home
            <p [innerHTML]="current_address?.slice(0,15)"></p>
          </a>

          <!-- <select (change)="select_location($event.target.value)">
            <option value="{{location.id}}" *ngFor="let location of loacations" [selected]="location.id==location_id">
              {{location.name}}</option>
            <option value="India">Durban</option>
          </select> -->
        </div>
      </div>
      <div class="g-search">
        <form action="">
          <div class="form-group mb-0">
            <input type="text" placeholder="Search here" class="form-control" #search
              (keyup)="onKeyUp($event,search.value)" name="search" [(ngModel)]="kayword">
            <button type="button" (click)="searchItem(search.value)"><i class="far fa-search"></i></button>
          </div>
        </form>
      </div>
    </div>
    <div class="g-cart-wishlist">

      <div class="d-flex align-items-center">
        <a href="javascript:void(0);" (click)="navToLogin()" class="g-login-signup" *ngIf="!isAuthenticate">
          <img src="assets/img/user-login-white.png" alt="">
          Login / Sign Up
        </a>
      </div>

      <div class="navDrop-wrap" *ngIf="authuser">
        <div class="navDrop_inner d-flex align-items-center">
          <div class="navDrop-user">
            <p *ngIf="authuser.name && authuser.name !=='undefined'">Hello {{ authuser.name }},</p>
            <a href="javascript:void(0);">Account & Lists</a>
          </div>

          <div class="nav-item dropdown user_dropdown" *ngIf="isAuthenticate">

            <a class="nav-link dropdown-toggle" href="javascript:void(0);" role="button" aria-haspopup="true">

              <img [src]="
          authuser.image
          ?authuser.image
          : 'assets/img/user-default.png' 
          " alt="">
            </a>
            <!-- <div class="dropdown-menu" aria-labelledby="user-drop">
              <div class="user_info">
                <div class="user_name">
                  <p>{{ authuser.name }}</p>
                  <div class="user_email">
                    <small>{{ authuser.email }}</small>
                  </div>
                </div>
                <ul>
                  <li>
                    <a href="javascript:void(0);" [routerLink]="['/my-profile']">
                      <i class="ion-android-person"></i>
                      My Profile
                    </a>
                  </li>
                  <li>
                    <a [routerLink]="['/my-wishlist']">
                      <i class="ion-heart"></i> My Wishlist
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" [routerLink]="['/recent-view']">
                      <i class="ion-ios-glasses"></i> Recent View
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" [routerLink]="['/change-password']">
                      <i class="ion-android-lock"></i>
                      Change Password
                    </a>
                  </li>

                  <li>
                    <a href="javascript:void(0);" (click)="logout()">
                      <i class="ion-log-out"></i>
                      Logout
                    </a>
                  </li>

                </ul>
              </div>
            </div> -->
          </div>

          <div class="customDrop">
            <h5>Your Account</h5>
            <ul class="list-unstyled mb-0">
              <li>
                <a href="javascript:void(0);" [routerLink]="['/my-profile']">Your Account</a>
              </li>
              <li>
                <a href="javascript:void(0);" [routerLink]="['/order-history']">Your Orders</a>
              </li>
              <li>
                <a href="javascript:void(0);" [routerLink]="['/my-wishlist']">Your Wish List</a>
              </li>
              <!-- <li>
                <a href="javascript:void(0);">Memberships</a>
              </li> -->
              <li>
                <a href="javascript:void(0);" (click)="logout()">Sign Out</a>
              </li>
            </ul>
          </div>

        </div>
      </div>

      <div class="g-bell">
        <a href="javascript:void(0)" class="dropdown-toggle" (mouseover)="onMouseHover()" (click)="unread_notifications()"><i class="fal fa-bell"></i>
          <span class="noty-manager-bubble" *ngIf="notification_count > 0">{{notification_count}}</span>
        </a>

        <div class="dropdown-menu" *ngIf="notifications && notifications.length > 0">
          <section class="panel">
            <header class="panel-heading">
              Notifications
            </header>
            <div id="notification-list" class="list-group list-group-alt">
                <div class="noty-manager-list-item noty-manager-list-item-error">
                 
                  <div class="activity-item" *ngFor="let notify of notifications; let i=index;"
                  [ngStyle]="{'display': 5 > i ? '' : 'none' }"> 
                    <div class="unread" *ngIf="notify.is_read == 0 "></div>
                      <!-- <div class="act-icon">
                        <i class="fa fa-shopping-cart text-success"></i>
                      </div> -->
                      <div class="notify-data" >
                          <a href="javascript:void(0);" [innerHTML]="notify.title"></a>
                          <div class="activity" >
                            <div [innerHTML]="notify.message.slice(0,50)+'...'"></div>
                            <span>{{notify.updated_at |  dateAgo}}</span> 
                          </div>
                      </div>
                  </div>

                  <!-- <div class="activity-item"> 
                      <div class="act-icon">
                        <i class="fa fa-shopping-cart text-success"></i>
                      </div>
                      <div class="notify-data">
                          <a href="javascript:void(0);">OEM license</a>
                          <div class="activity">Eugene ordered 2 copies o 
                            <span>14 min ago</span> 
                          </div>
                      </div>
                  </div>

                  <div class="activity-item"> 
                      <div class="act-icon">
                        <i class="fa fa-shopping-cart text-success"></i>
                      </div>
                      <div class="notify-data">
                          <a href="javascript:void(0);">OEM license</a>
                          <div class="activity">Eugene ordered 2 copies o 
                            <span>14 min ago</span> 
                          </div>
                      </div>
                  </div> -->

                </div>           

            </div>
            <footer class="panel-footer" *ngIf="currentUrl !=='/notifications'">
              <a href="javascript:void(0);" [routerLink]="['/notifications']">See all <i class="fas fa-arrow-right"></i></a>
            </footer>
          </section>

        </div>
      </div>


      <div class="g-cart cart-popup-open" (click)="gcardpopup('open')" [ngStyle]="{'pointer-events': is_clicked ? 'none' : '' }">
        <div class="g-cart-inr">
          <span class="g-cart-icon">
            <img src="assets/img/cart.png" alt="Cart">
            <span class="g-cart-bagde">{{cart_count}}</span>
          </span>
          <div class="g-cart-text">
            <span>My Cart</span>
            <span>ZAR {{sub_total | number : '1.2-2'}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="overlay"></div> -->
  <div class="g-header-bottom" [ngClass]="menuShow ? 'show' : ''">
    <div class="g-header-bottom-inner">
      <!-- <div class="g-category">

      <button type="button" data-toggle="collapse" data-target="#navCatContent" aria-expanded="false"
        class="categories_btn collapsed g-category-nr d-flex align-items-center">
        <img src="assets/img/category.png" alt="">
        <span>All Categories</span>
        <i class="fa fa-angle-down"></i>
      </button>
      <div id="navCatContent" class="nav_cat navbar collapse">
        <ul *ngIf="categorys && categorys.length > 0">
          <li class="dropdown dropdown-mega-menu" *ngFor="let category of categorys">
            <a class="dropdown-item nav-link dropdown-toggler  has-cate " [ngClass]="{'active': category.id == category_id}" href="javascript:void(0);" (click)="selectCategory(category.id)"><span>{{category.name}}</span></a>

          </li>
          <li class="dropdown dropdown-mega-menu">
            <a class="dropdown-item nav-link dropdown-toggler has-cate" href="#"><span>Men's</span></a>

          </li>
          <li class="dropdown dropdown-mega-menu">
            <a class="dropdown-item nav-link dropdown-toggler has-cate" href="#"><span>Kid's</span></a>

          </li>
          <li class="dropdown dropdown-mega-menu">
            <a class="dropdown-item nav-link dropdown-toggler has-cate" href="#"><span>Accessories</span></a>

          </li>
          <li><a class="dropdown-item nav-link nav_item has-cate" href="#"> <span>Clothing</span></a></li>
          <li><a class="dropdown-item nav-link nav_item has-cate" href="#"> <span>Shoes</span></a></li>
          <li><a class="dropdown-item nav-link nav_item has-cate" href="#"><span>Watches</span></a></li>
          <li><a class="dropdown-item nav-link nav_item has-cate" href="#"> <span>Jewellery</span></a></li>
          <li><a class="dropdown-item nav-link nav_item has-cate" href="#"> <span>Health &amp; Beauty</span></a> </li>
          <li><a class="dropdown-item nav-link nav_item has-cate" href="#"> <span>Sports</span></a></li>

        </ul>
      </div>
    </div> -->

      <div class="g-category">

        <button type="button" (click)="productList()" class="categories_btn  g-category-nr d-flex align-items-center">
          <img src="assets/img/category.png" alt="">
          <span>All Categories</span>
          <!-- <i class="fa fa-angle-down"></i> -->
        </button>
        <!-- <div id="navCatContent" class="nav_cat navbar collapse">
        <ul *ngIf="categorys && categorys.length > 0">
          <li class="dropdown dropdown-mega-menu" *ngFor="let category of categorys">
            <a class="dropdown-item nav-link dropdown-toggler has-cate" href="javascript:void(0);"
            [ngClass]="{'active': category.id == category_id}" (click)="selectCategory(category.id)" data-toggle="dropdown"
              *ngIf="category.sub_category && category.sub_category.length > 0"><span>{{category.name}}</span></a>
            <div class="dropdown-menu" *ngIf="category.sub_category && category.sub_category.length > 0">
              <ul class="mega-menu-row">
                <li class="mega-menu-col">
                  <ul class="d-lg-flex row">
                    <li class="mega-menu-col col-lg-4">
                      <ul>
                        <li *ngFor="let sub_category of category.sub_category"><a
                            class="dropdown-item nav-link nav_item"
                            [ngClass]="{'active': sub_category.id == sub_category_id}" href="javascript:void(0);"
                            (click)="selectSubCategory(category.id,sub_category.id)">{{sub_category.name}}
                            sed</a></li>
                        <li><a class="dropdown-item nav-link nav_item" href="#">Donec
                            porttitor</a></li>
                        <li><a class="dropdown-item nav-link nav_item" href="#">Donec vitae
                            facilisis</a></li>
                        <li><a class="dropdown-item nav-link nav_item" href="#">Curabitur
                            tempus</a></li>
                        <li><a class="dropdown-item nav-link nav_item" href="#">Vivamus in
                            tortor</a></li>
                        <li><a class="dropdown-item nav-link nav_item" href="#">Donec vitae
                            ante ante</a></li>
                        <li><a class="dropdown-item nav-link nav_item" href="#">Etiam ac
                            rutrum</a></li>
                        <li><a class="dropdown-item nav-link nav_item" href="#">Quisque
                            condimentum</a></li>
                      </ul>
                    </li>
                    <li class="mega-menu-col col-lg-4">
                      <ul>
                        <li><a class="dropdown-item nav-link nav_item" href="#">Curabitur
                            laoreet</a></li>
                        <li><a class="dropdown-item nav-link nav_item" href="#">Vivamus in
                            tortor</a></li>
                        <li><a class="dropdown-item nav-link nav_item" href="#">Donec vitae
                            facilisis</a></li>
                        <li><a class="dropdown-item nav-link nav_item" href="#">Quisque
                            condimentum</a></li>
                        <li><a class="dropdown-item nav-link nav_item" href="#">Etiam ac
                            rutrum</a></li>
                        <li><a class="dropdown-item nav-link nav_item" href="#">Donec vitae
                            ante ante</a></li>
                        <li><a class="dropdown-item nav-link nav_item" href="#">Donec
                            porttitor</a></li>
                        <li><a class="dropdown-item nav-link nav_item" href="#">Curabitur
                            tempus</a></li>
                      </ul>
                    </li>
                    <li class="mega-menu-col col-lg-4">
                      <ul>
                        <li><a class="dropdown-item nav-link nav_item" href="#">Curabitur
                            laoreet</a></li>
                        <li><a class="dropdown-item nav-link nav_item" href="#">Vivamus in
                            tortor</a></li>
                        <li><a class="dropdown-item nav-link nav_item" href="#">Donec vitae
                            facilisis</a></li>
                        <li><a class="dropdown-item nav-link nav_item" href="#">Quisque
                            condimentum</a></li>
                        <li><a class="dropdown-item nav-link nav_item" href="#">Etiam ac
                            rutrum</a></li>
                        <li><a class="dropdown-item nav-link nav_item" href="#">Donec vitae
                            ante ante</a></li>
                        <li><a class="dropdown-item nav-link nav_item" href="#">Donec
                            porttitor</a></li>
                        <li><a class="dropdown-item nav-link nav_item" href="#">Curabitur
                            tempus</a></li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li class="mega-menu-col">
                  <ul class="d-lg-flex row">
                    <li class="mega-menu-col col-lg-4">
                      <ul>
                        <li *ngFor="let sub_category of category.sub_category"><a
                            class="dropdown-item nav-link nav_item"
                            [ngClass]="{'active': sub_category.id == sub_category_id}" href="javascript:void(0);"
                            (click)="selectSubCategory(category.id,sub_category.id)">{{sub_category.name}}
                            sed</a></li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li class="mega-menu-col">
                  <ul class="d-lg-flex row">
                    <li class="mega-menu-col col-lg-4">
                      <ul>
                        <li *ngFor="let sub_category of category.sub_category"><a
                            class="dropdown-item nav-link nav_item"
                            [ngClass]="{'active': sub_category.id == sub_category_id}" href="javascript:void(0);"
                            (click)="selectSubCategory(category.id,sub_category.id)">{{sub_category.name}}
                            sed</a></li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <a class="dropdown-item nav-link nav_item" href="javascript:void(0);" (click)="selectCategory(category.id)"
              [ngClass]="{'active': category.id == category_id}"
              *ngIf="category.sub_category && category.sub_category.length == 0"> <span>{{category.name}}</span></a>
          </li>
          <!-- <li></li> -->
        <!-- <li class="dropdown dropdown-mega-menu">
                      <a class="dropdown-item nav-link dropdown-toggler has-cate" href="#"
                          data-toggle="dropdown"><span>Men's</span></a>
                      <div class="dropdown-menu">
                          <ul class="mega-menu d-lg-flex">
                              <li class="mega-menu-col col-lg-7">
                                  <ul class="d-lg-flex">
                                      <li class="mega-menu-col col-lg-6">
                                          <ul>
                                              <li><a class="dropdown-item nav-link nav_item" href="#">Vestibulum
                                                      sed</a></li>
                                              <li><a class="dropdown-item nav-link nav_item" href="#">Donec
                                                      porttitor</a></li>
                                              <li><a class="dropdown-item nav-link nav_item" href="#">Donec vitae
                                                      facilisis</a></li>
                                              <li><a class="dropdown-item nav-link nav_item" href="#">Curabitur
                                                      tempus</a></li>
                                              <li><a class="dropdown-item nav-link nav_item" href="#">Vivamus in
                                                      tortor</a></li>
                                              <li><a class="dropdown-item nav-link nav_item" href="#">Donec vitae
                                                      ante ante</a></li>
                                              <li><a class="dropdown-item nav-link nav_item" href="#">Etiam ac
                                                      rutrum</a></li>
                                          </ul>
                                      </li>
                                      <li class="mega-menu-col col-lg-6">
                                          <ul>
                                              <li><a class="dropdown-item nav-link nav_item" href="#">Curabitur
                                                      laoreet</a></li>
                                              <li><a class="dropdown-item nav-link nav_item" href="#">Vivamus in
                                                      tortor</a></li>
                                              <li><a class="dropdown-item nav-link nav_item" href="#">Donec vitae
                                                      facilisis</a></li>
                                              <li><a class="dropdown-item nav-link nav_item" href="#">Quisque
                                                      condimentum</a></li>
                                              <li><a class="dropdown-item nav-link nav_item" href="#">Etiam ac
                                                      rutrum</a></li>
                                              <li><a class="dropdown-item nav-link nav_item" href="#">Donec vitae
                                                      ante ante</a></li>
                                              <li><a class="dropdown-item nav-link nav_item" href="#">Donec
                                                      porttitor</a></li>
                                          </ul>
                                      </li>
                                  </ul>
                              </li>
  
                          </ul>
                      </div>
  
                  </li>
  
                </ul>
              </div>
            </li>
            <li class="dropdown dropdown-mega-menu">
              <a class="dropdown-item nav-link dropdown-toggler" href="#" data-toggle="dropdown"><span>Kid's</span></a>
  


        </ul>

      </div> -->
      </div>

      <div class="g-navigation">
        <ul class="menu">
          <!-- <li class="" [ngClass]="{'active': currentUrl === '/'}"><a href="javascript:void(0);"
            [routerLink]="['/']">Home</a></li>
        <li [ngClass]="{'active': currentUrl === '/about-us'}"><a href="javascript:void(0);"
            [routerLink]="['/about-us']">About Us</a></li>
        <li [ngClass]="{'active': currentUrl === '/stores'}"><a href="javascript:void(0);"
            [routerLink]="['/stores']">Stores</a></li>
        <li [ngClass]="{'active': currentUrl === '/products'}"><a href="javascript:void(0);"
            (click)="productList()">Products</a></li>
        <li [ngClass]="{'active': currentUrl === '/contact-us'}"><a href="javascript:void(0);"
            [routerLink]="['/contact-us']">Contact</a></li> -->

          <li>
            <a href="javascript:void(0);">Today's Deals</a>
          </li>
          <li>
            <a href="javascript:void(0);">Top Recipes</a>
          </li>
          <li>
            <a href="javascript:void(0);">LIfestyle Diets</a>
          </li>
          <li>
            <a href="javascript:void(0);">Deals & Promotions</a>
          </li>
          <li>
            <a href="javascript:void(0);">Premium Membership</a>
          </li>
          <li>
            <a href="javascript:void(0);">G-Coins</a>
          </li>
        </ul>
      </div>
      <!-- <a href="javascript:void(0);" (click)="navToLogin()" class="g-login-signup" *ngIf="!isAuthenticate">
      <img src="assets/img/user-login.png" alt="">
      Login / Sign Up
    </a> -->

      <!-- <a href="javascript:void(0);" (click)="logout()" class="g-login-signup" *ngIf="isAuthenticate">
      <img src="assets/img/user-login.png" alt="">
      Logout
    </a> -->
    </div>
  </div>
</header>


<!-- Cart Sidebar -->
<div class="cart-sidepanel cart-popup" [ngClass]="(card_popup_opean=='open')?'open':''">


  <div class="g-cart-header">
    <h2>My Cart <span *ngIf="cart_count > 0">({{cart_count}} Items)</span></h2>
    <div class="g-close-btn close-task-popup" (click)="gcardpopupclose('close')">
      <i class="far fa-times"></i>
    </div>
  </div>
  <div class="noRecord_all" *ngIf="cart_detail.length == 0">
    <div>
      <div class="noRecord_wall">
        <img src="assets/img/no-record.png" alt="">
      </div>
      <p>Cart Empty.</p>
    </div>
  </div>
  <div class="g-card-body" *ngIf="cart_detail && cart_detail.length > 0">
    <div class="g-inner">
      <div class="g-cart-list" [ngStyle]="{'display': is_last_minuts_buys== '1' ? 'none' : 'block' }">
        <ul>
          <li *ngFor="let cart of cart_detail">
            <div class="cart-img" (click)="seeproductdetail(cart.product_id)">
              <img src="{{cart.image}}" alt="">
            </div>
            <div class="cart-conatent">
              <span class="tag" *ngIf="cart.discount_per > 0">{{cart.discount_per}}% Off</span>
              <h2>{{cart.name}}</h2>
              <div class="price-section">
                <div class="qty-btn">
                  <button class="value-btn" [ngClass]="{'disabled': cart.quantity == 1}" type="button"
                    (click)="decreaseQuantity(cart.quantity,cart.id,cart.product_id,cart.product_variant_id,cart.unit_id)"><i
                      class="fa fa-minus"></i></button>
                  <input type="text" class="value-input" style="pointer-events: none;" value="{{cart.quantity}}">
                  <button class="value-btn" type="button"
                    (click)="increaseQuantity(cart.quantity,cart.id,cart.product_id,cart.product_variant_id,cart.unit_id)"><i
                      class="fa fa-plus"></i></button>
                </div>
                <div class="cart-price">
                  <a href="javascript:void(0);" (click)="openRemoveItem(remove_from_cart, cart.id)"><i
                      class="far fa-times"></i></a>
                  <div class="price-inr">ZAR {{ cart.discount_per !== 0 ? cart.discounted_price : cart.price}} <span
                      *ngIf="cart.discount_per !== 0">ZAR {{cart.price}}</span></div>
                </div>
                <div class="zar-right">
                  ZAR {{cart.total_amount}}
                </div>
              </div>
            </div>
          </li>
          <!-- <li>
            <div class="cart-img">
              <img src="assets/img/product/01.png" alt="">
            </div>
            <div class="cart-conatent">
              <span class="tag">22% Off</span>
              <h2>Chicken Bouillon</h2>
              <div class="price-section">
                <div class="qty-btn">
                  <button class="value-btn" type="button"><i class="fa fa-minus"></i></button>
                  <input type="text" class="value-input" value="1">
                  <button class="value-btn" type="button"><i class="fa fa-plus"></i></button>
                </div>
                <div class="cart-price">
                  <i class="far fa-times"></i>
                  <div class="price-inr">ZAR 21 <span>ZAR 23</span></div>
                </div>
                <div class="zar-right">
                  ZAR 38
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="cart-img">
              <img src="assets/img/product/01.png" alt="">
            </div>
            <div class="cart-conatent">
              <span class="tag">22% Off</span>
              <h2>Chicken Bouillon</h2>
              <div class="price-section">
                <div class="qty-btn">
                  <button class="value-btn" type="button"><i class="fa fa-minus"></i></button>
                  <input type="text" class="value-input" value="1">
                  <button class="value-btn" type="button"><i class="fa fa-plus"></i></button>
                </div>
                <div class="cart-price">
                  <i class="far fa-times"></i>
                  <div class="price-inr">ZAR 21 <span>ZAR 23</span></div>
                </div>
                <div class="zar-right">
                  ZAR 38
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="cart-img">
              <img src="assets/img/product/01.png" alt="">
            </div>
            <div class="cart-conatent">
              <span class="tag">22% Off</span>
              <h2>Chicken Bouillon</h2>
              <div class="price-section">
                <div class="qty-btn">
                  <button class="value-btn" type="button"><i class="fa fa-minus"></i></button>
                  <input type="text" class="value-input" value="1">
                  <button class="value-btn" type="button"><i class="fa fa-plus"></i></button>
                </div>
                <div class="cart-price">
                  <i class="far fa-times"></i>
                  <div class="price-inr">ZAR 21 <span>ZAR 23</span></div>
                </div>
                <div class="zar-right">
                  ZAR 38
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="cart-img">
              <img src="assets/img/product/01.png" alt="">
            </div>
            <div class="cart-conatent">
              <span class="tag">22% Off</span>
              <h2>Chicken Bouillon</h2>
              <div class="price-section">
                <div class="qty-btn">
                  <button class="value-btn" type="button"><i class="fa fa-minus"></i></button>
                  <input type="text" class="value-input" value="1">
                  <button class="value-btn" type="button"><i class="fa fa-plus"></i></button>
                </div>
                <div class="cart-price">
                  <i class="far fa-times"></i>
                  <div class="price-inr">ZAR 21 <span>ZAR 23</span></div>
                </div>
                <div class="zar-right">
                  ZAR 38
                </div>
              </div>
            </div>
          </li> -->
        </ul>
      </div>
      <div id="accordion" *ngIf="quick_buy_product && quick_buy_product.length > 0">
        <div class="card">
          <div class="card-header" id="headingThree">
            <h5 class="mb-0">
              <button class="btn collapsed btn-buyMOre" data-toggle="collapse" data-target="#collapseThree"
                aria-expanded="false" aria-controls="collapseThree" (click)="lastminutbuy()">
                <img src="assets/img/buy-more.png" alt=""> Last Minute Buy
              </button>
            </h5>
          </div>
          <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
            <div class="card-body clearfix">
              <div class="buy-more-content">
                <div class="buy-more-list" *ngFor="let product of quick_buy_product">
                  <div class="cart-img">
                    <a href="javascript:void(0);" (click)="seeproductdetail(product.id)">
                      <!-- <img src="assets/img/product/01.png" alt="Product Image"> -->
                      <img src="{{ product.image }}" alt="Product Image">
                    </a>
                  </div>
                  <div class="g-product-tags">
                    <span class="g-new" *ngIf="product.is_new == 1">
                      New
                    </span>
                    <!-- <span class="g-sele" *ngIf="product.is_on_sale == 1">
                      Sale
                    </span> -->
                    <span class="g-oos" *ngIf="product.is_out_of_stock == 1">
                      Out of Stock
                    </span>
                  </div>
                  <div class="cart-conatent">
                    <div class="cart-conatent0">
                      <span class="tag" *ngIf="product.product_variant[0].discount_per !== 0">
                        {{ product.product_variant[0].discount_per }}% Off
                      </span>
                      <h2>{{ product.name }}</h2>
                      <div class="price-section">
                        <div class="cart-price">
                          <div class="price-inr">
                            ZAR
                            {{ product.product_variant[0].discount_per !== 0 ? product.product_variant[0].discounted_price : product.product_variant[0].price}}
                            <span *ngIf="product.product_variant[0].discount_per !== 0">
                              ZAR {{ product.product_variant[0].price }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="qtySelect_box">
                      <div class="pd-select" *ngIf="product.product_variant && product.product_variant.length > 0">
                        <select [id]="'select'+product.id">
                          <!-- <option value="{{variant.id}}" *ngFor="let variant of product.product_variant">{{variant.unit_name}} - ZAR <span *ngIf="variant.discount_per == 0">{{variant.price}}</span><span *ngIf="variant.discount_per > 0">{{variant.discounted_price}}</span></option> -->
                          <option *ngFor="let variant of product.product_variant"
                            [value]="variant.id+'-'+variant.unit_id">{{variant.unit_name}} - ZAR <span
                              *ngIf="variant.discount_per == 0">{{variant.price}}</span><span
                              *ngIf="variant.discount_per > 0">{{variant.discounted_price}}</span></option>
                          <!-- <option value="1 kg - ZAR 5">2 kg - ZAR 9</option>
                              <option value="1 kg - ZAR 5">3 kg - ZAR 13</option> -->
                        </select>
                      </div>

                      <div class="g-qty-inner">
                        <form action="">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text" id="basic-addon1">Qty</span>
                            </div>
                            <input #quantity type="text" class="form-control" placeholder="" value="1"
                              (keypress)="numberOnly($event)">
                          </div>
                        </form>
                      </div>
                    </div>

                    <div class="cart-btnWrap">
                      <button (click)="addToCart(product.id,quantity.value,'select'+product.id)"
                        [ngClass]="{'disabled': product.is_out_of_stock == 1}" class="cart-btn" type="button">Add to
                        Cart</button>
                    </div>
                  </div>
                </div>
                <!-- <div class="buy-more-list">
                  <div class="cart-img">
                    <img src="assets/img/product/01.png" alt="">
                  </div>
                  <div class="cart-conatent">
                    <div class="cart-conatent0">
                      <span class="tag">22% Off</span>
                      <h2>Chicken Bouillon</h2>
                      <div class="price-section">
                        <div class="cart-price">
                          <div class="price-inr">ZAR 21 <span>ZAR 23</span></div>
                        </div>
                      </div>
                    </div>
                    <div class="cart-btnWrap">
                      <button class="cart-btn" type="button">Add to Card</button>
                    </div>
                  </div>
                </div>
                <div class="buy-more-list">
                  <div class="cart-img">
                    <img src="assets/img/product/01.png" alt="">
                  </div>
                  <div class="cart-conatent">
                    <div class="cart-conatent0">
                      <span class="tag">22% Off</span>
                      <h2>Chicken Bouillon</h2>
                      <div class="price-section">
                        <div class="cart-price">
                          <div class="price-inr">ZAR 21 <span>ZAR 23</span></div>
                        </div>
                      </div>
                    </div>
                    <div class="cart-btnWrap">
                      <button class="cart-btn" type="button">Add to Card</button>
                    </div>
                  </div>
                </div>
                <div class="buy-more-list">
                  <div class="cart-img">
                    <img src="assets/img/product/01.png" alt="">
                  </div>
                  <div class="cart-conatent">
                    <div class="cart-conatent0">
                      <span class="tag">22% Off</span>
                      <h2>Chicken Bouillon</h2>
                      <div class="price-section">
                        <div class="cart-price">
                          <div class="price-inr">ZAR 21 <span>ZAR 23</span></div>
                        </div>
                      </div>
                    </div>
                    <div class="cart-btnWrap">
                      <button class="cart-btn" type="button">Add to Card</button>
                    </div>
                  </div>
                </div>
                <div class="buy-more-list">
                  <div class="cart-img">
                    <img src="assets/img/product/01.png" alt="">
                  </div>
                  <div class="cart-conatent">
                    <div class="cart-conatent0">
                      <span class="tag">22% Off</span>
                      <h2>Chicken Bouillon</h2>
                      <div class="price-section">
                        <div class="cart-price">
                          <div class="price-inr">ZAR 21 <span>ZAR 23</span></div>
                        </div>
                      </div>
                    </div>
                    <div class="cart-btnWrap">
                      <button class="cart-btn" type="button">Add to Card</button>
                    </div>
                  </div>
                </div>
                <div class="buy-more-list">
                  <div class="cart-img">
                    <img src="assets/img/product/01.png" alt="">
                  </div>
                  <div class="cart-conatent">
                    <div class="cart-conatent0">
                      <span class="tag">22% Off</span>
                      <h2>Chicken Bouillon</h2>
                      <div class="price-section">
                        <div class="cart-price">
                          <div class="price-inr">ZAR 21 <span>ZAR 23</span></div>
                        </div>
                      </div>
                    </div>
                    <div class="cart-btnWrap">
                      <button class="cart-btn" type="button">Add to Card</button>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cart-total-section">
        <div class="total-section">
          <div class="d-flex align-items-center justify-content-between" *ngIf="sub_total">
            <h6>Sub Total</h6>
            <span>ZAR {{sub_total | number : '1.2-2'}}</span>
          </div>
          <!-- <div class="d-flex align-items-center justify-content-between mb-0" >
            <h6>Delivery Charges <span data-toggle="tooltip" data-placement="top" title="{{delivery_charges_msg}}">
                <img src="assets/img/info.png" alt="">
              </span></h6>
            <span class="clor-orange">+ ZAR {{delivery_charges | number : '1.2-2'}}</span>
          </div> -->
        </div>
        <div class="cart-total-section">
          <div class="saving-section">
            <div class="d-flex align-items-center justify-content-between ">
              <h6>Total Savings</h6>
              <span class="clor-orange">ZAR {{total_saving | number : '1.2-2'}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="cart-total-section fix-section">
        <div class="saving-section border-0">
          <p>Promo code can be applied on payment page.
          </p>
          <button type="button" class="checkout-btn" (click)="gcardpopupclose('close')" [routerLink]="['/cart']">Proceed
            to Checkout<span>ZAR
              {{sub_total | number : '1.2-2'}}</span></button>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Button trigger modal -->
<!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#locationmodal">
        Launch demo modal
    </button> -->

<!-- Modal -->
<div class="modal fade location_modal" id="locationmodal" tabindex="-1" role="dialog"
  aria-labelledby="locationmodalLabel" aria-hidden="true" bsModal #locationmodal="bs-modal"
  [config]="{backdrop:'static',keyboard : false}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body p-0">
        <div class="location-body">
          <div class="location-aside">
            <div class="location-logo">
              <img src="assets/img/location-logo.png" alt="">
            </div>
            <div class="location-aside-text">
              <h3>Welcome</h3>
              <p>Lorem ipsum dolor sit amet,
                consectetur adipiscing elit.</p>
            </div>
            <div class="location-wall">
              <img src="assets/img/location-wall.png" alt="">
            </div>
          </div>
          <div class="location-content">
            <div class="w-100">
              <div class="location-content-inner">
                <h3>The On-Demand Shopping Marketplace</h3>
                <h4>One Stop Destination for all your shopping needs</h4>
                <h5>ZAP.. You Order... Zoom...We Deliver...</h5>
              </div>


              <div class="location-input">
                <div class="position-relative">
                  <i class="fas fa-map-marker-alt"></i>
                  <!-- <input type="text" placeholder="Enter your address" name="" id=""> -->
                  <input id="search_location" type="text" (keydown.enter)="$event.preventDefault()"
                    placeholder="Search Nearest Location" autocorrect="off" autocapitalize="off" spellcheck="off"
                    type="text" (keyup)="resetLatLong()" #location_search />
                  <div *ngIf="is_address_search" class="text-danger help-inline">
                    <div *ngIf="is_address_search">Please search nearest loaction</div>
                  </div>
                </div>

                <button class="btn" (click)="addAddress(location_search.value)">Deliver Here!</button>
              </div>
            </div>
          </div>
        </div>
      </div>


      <!-- <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" style="display: none;">
        <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"></agm-marker>
      </agm-map> -->
      <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" style="display: none;">
        <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"></agm-marker>
      </agm-map>

    </div>
  </div>
</div>