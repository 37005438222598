<footer class="g-footer">
        <div class="g-footer-top">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3">
                        <div class="g-footer-widget">
                            <div class="g-footer-logo">
                                <a href="javasript:void(0);">
                                    <img src="assets/img/footer-logo.png" alt="Grozap">
                                </a>
                            </div>
                            <div class="g-footer-dec" *ngIf="footerDetail && footerDetail.footer_logo_tag_line">
                                {{footerDetail.footer_logo_tag_line}}
                            </div>
                            <div class="g-footer-contact" *ngIf="footerDetail && footerDetail.talk_to_support_number">
                                <a href="tel:{{footerDetail.talk_to_support_number}}">
                                    <span class="g-talk-icon-wrap">
                                        <span class="g-talk-icon">
                                            <img src="assets/img/talk.png" alt="">
                                        </span>
                                    </span>
                                    <span class="g-talk-text-wrap">
                                        <span class="g-talk-text">Talk to our Support</span>
                                        <span class="g-talk-number">{{footerDetail.talk_to_support_number}}</span>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-9 g-footer-nav">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="g-footer-widget">
                                    <h3>Quick Links</h3>
                                    <ul>
                                        <li>
                                            <a href="javascript:void(0);" [routerLink]="['/about-us']">About Us</a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);" [routerLink]="['/stores']">Stores</a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);" (click)="productList()">Products</a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);" [routerLink]="['/contact-us']">Contact</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="g-footer-widget">
                                    <h3>My Account</h3>
                                    <ul>
                                        <li>
                                            <a href="javascript:void(0);" [routerLink]="['/my-profile']">My Account</a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);" [routerLink]="['/order-history']" >Order History</a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);" [routerLink]="['/manage-addresses']" >Addresses</a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);" [routerLink]="['/my-wishlist']">My Wishlist</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="g-footer-widget">
                                    <h3>Infromation</h3>
                                    <ul>
                                        <li>
                                            <a href="javascript:void(0);" [routerLink]="['/terms']">Terms of Use</a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);" [routerLink]="['/privacy-policy']">Privacy Policy</a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);" [routerLink]="['/faq']" >FAQ’s</a> 
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);" [routerLink]="['/refund-policy']">Refund Policy</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="g-footer-widget">
                                    <h3>Business</h3>
                                    <ul>
                                        <li>
                                            <a href="javascript:;"> Become a Customer </a>
                                        </li>
                                        <li>
                                            <a href="javascript:;"> Become a Shopper</a>
                                        </li>
                                        <li>
                                            <a href="javascript:;"> Become a Driver</a>
                                        </li>
                                        <li>
                                            <a href="javascript:;"> Become a Partner</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="g-footer-mid">
            <div class="container">
                <div class="row">
                    <div class="col-md-4 text-center">
                        <div class="g-footer-widget">
                            <h3>We Accept</h3>
                            <div class="g-payment-accept">
                                <img src="assets/img/payment_new.png" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 text-center" *ngIf="footerDetail">
                        <div class="g-footer-widget">
                            <h3>Download App</h3>
                            <div class="g-download-app">
                                <a  href="{{footerDetail.android}}" target="_blank">
                                    <img src="assets/img/google-play.png" alt="">
                                </a>
                                <a  href="{{footerDetail.iphone}}" target="_blank">
                                    <img src="assets/img/apple-store.png" alt="">
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 text-center" *ngIf="footerDetail">
                        <div class="g-footer-widget">
                            <h3>Socialize with Us</h3>
                            <div class="g-social">
                                <a href="{{footerDetail.facebook_link}}"target="_blank">
                                    <i class="fab fa-facebook-f"></i>
                                </a>
                                <a href="{{footerDetail.instagram_link}}"target="_blank">
                                    <i class="fab fa-instagram"></i>
                                </a>
                                <a href="{{footerDetail.twitter_link}}"target="_blank">
                                    <i class="fab fa-twitter"></i>
                                </a>
                                <a href="{{footerDetail.youtube_link}}"target="_blank">
                                    <i class="fab fa-youtube"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="g-footer-service">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3 col-sm-6">
                        <div class="g-single-static">
                            <img src="assets/img/support.png" alt="">
                            <div class="single-static-meta">
                                <h4>24/7 Services</h4>
                                <p>On all orders over ZAR 75.00</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6">
                        <div class="g-single-static">
                            <img src="assets/img/card.png" alt="">
                            <div class="single-static-meta">
                                <h4>Big Savings</h4>
                                <p>Offer Only Best Deal</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6">
                        <div class="g-single-static">
                            <img src="assets/img/truck.png" alt="">
                            <div class="single-static-meta">
                                <h4>Fast Delivery</h4>
                                <p>Free Shipping</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6">
                        <div class="g-single-static">
                            <img src="assets/img/check-circle.png" alt="">
                            <div class="single-static-meta">
                                <h4>Quality Assurance</h4>
                                <p>You can trust us</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="g-footer-copyright" *ngIf="footerDetail && footerDetail.copyright_text">{{footerDetail.copyright_text}}</div>
    </footer>