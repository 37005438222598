import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reset-pwd-layout',
  templateUrl: './reset-pwd-layout.component.html',
  styleUrls: ['./reset-pwd-layout.component.css']
})
export class ResetPwdLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  scrollTop(event) {
    window.scroll(0, 0); 
  }

}
