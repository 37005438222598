import { Injectable } from '@angular/core';
import {HttpResponse,HttpRequest,HttpHandler,HttpEvent,HttpInterceptor} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from '../services/loader.service';
import {  Router } from '@angular/router';
@Injectable()

export class LoaderInterceptorService implements HttpInterceptor {
    private requests: HttpRequest<any>[] = [];

    constructor(
        private loaderService: LoaderService,
        private router :Router
    ) { }
    
    removeRequest(req: HttpRequest<any>) {
        const i = this.requests.indexOf(req);
        if (i >= 0) {
           this.requests.splice(i, 1);
        }
     
        
        // if(req.url.split('/')[req.url.split('/').length -1].split('?')[0] !=='save-chat-history')
        if(localStorage.getItem('add_item') && localStorage.getItem('add_item')== '1' )
        {
            this.loaderService.isLoading.next(this.requests?.length > 0);
        }
        else
        {
            this.loaderService.isLoading.next(this.requests?.length > 4);
        }
        
        
       
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.requests.push(req);
        let reqSegement = req.url.split('/')[req.url.split('/')?.length -1];
 
        if(reqSegement.split('?')[0] == 'unread-notifications' ||reqSegement.split('?')[0] ==  'cart-count' ||reqSegement.split('?')[0] == 'read-all-notifications' || reqSegement.split('?')[0] =='notifications'){
           //do not show loading screen
        }else{
     
            this.loaderService.isLoading.next(true);
  
        }
      
        return Observable.create(observer => {
          const subscription = next.handle(req)
            .subscribe(
                event => {
                    if (event instanceof HttpResponse) {
                        this.removeRequest(req);
                        observer.next(event);
                    }
                },
                err => {
                    if(err.status == 401)
                    {                        
                        localStorage.removeItem('authUser')
                        //window.location.href = '' 
                        this.router.navigate(['/login'])
                    }
                    this.removeRequest(req);
                    observer.error(err);
                },
                () => {
                    this.removeRequest(req);
                    observer.complete();
                }
            );
            return () => {
                this.removeRequest(req);
                subscription.unsubscribe();
            };
        });
    }
}
